import TailwindDrawer from "components/tailwind/headlessTailwind/TailwindDrawer";
import { useMemo, useState } from "react";
import { Campaign } from "shared/types/Campaign";
import CreateSignOffSelection from "./drawerViews/CreateSignOffSelectionView";
import ManualSignOffView from "./drawerViews/ManualSignOffView";
import RequestClientSignOffDrawer from "./drawerViews/RequestClientSignOffView";

type CreateClientSignOffDrawerProps = {
  refetchCampaign(): void;
  campaign?: Campaign;
};

const CreateClientSignOffDrawer: React.FC<CreateClientSignOffDrawerProps> = ({ refetchCampaign, campaign }) => {
  const [selection, setSelection] = useState<number | undefined>(undefined);
  const [title, setTitle] = useState<string>("Client sign off");
  const [subtitle, setSubtitle] = useState<string>("Select your preferred method for signing off the completed work associated with this campaign's deliverables.");

  const getView = useMemo(() => {
    if (selection === 1) {
      setSubtitle("Request client approval once the campaign is ready");
      setTitle("Client sign off");
      return <RequestClientSignOffDrawer refetchCampaign={refetchCampaign} campaign={campaign} onBackCallback={setSelection}/>;
    } else if (selection === 2) {
      setSubtitle(
        "By using the manual client sign-off option, you'll need to upload proof that the client has approved the completed work for the relevant deliverables that you select below",
      );
      setTitle("Manual client sign off");
      return <ManualSignOffView refetchCampaign={refetchCampaign} campaign={campaign} onBackCallback={setSelection}/>;
    } else {
      setSubtitle("Select your preferred method for signing off the completed work associated with this campaign's deliverables.");
      setTitle("Client sign off");
      return <CreateSignOffSelection onSelectionCallback={setSelection} />;
    }
  }, [selection, campaign]);

  return (
    <>
      <TailwindDrawer title={title} subtitle={subtitle}>
        {getView}
      </TailwindDrawer>
    </>
  );
};

export default CreateClientSignOffDrawer;

import { MonetBadgeVariants } from "components/tailwind/MonetBadge";
import { Invoice, InvoiceFundingStatus } from "shared/types/Invoice";

export const getInvoiceStatusSettings = (status: Invoice["invoiceStatus"] | InvoiceFundingStatus): { color: MonetBadgeVariants["variant"] } => {
  switch (status) {
    case "DUE":
      return {
        color: "gray",
      };
    case "ACTIVE":
      return {
        color: "green",
      };
    case "SUBMITTED":
      return {
        color: "blue",
      };
    case "OVERDUE":
      return {
        color: "yellow",
      };
    case "REJECTED":
      return {
        color: "red",
      };
    case "SETTLED": {
      return {
        color: "purple",
      };
    }
    default:
      return {
        color: "gray",
      };
  }
};

import TailwindDialog from "components/tailwind/headlessTailwind/TailwindDialog";
import { Campaign } from "shared/types/Campaign";
import { toast } from "sonner";
import { useResendClientSignOffRequestMutation } from "views/campaigns/graphql/mutations.generated";

type ResendClientSignOffModalProps = {
  campaign: Campaign;
  clientSignOffId: number;
};

const ResendClientSignOffModal: React.FC<ResendClientSignOffModalProps> = ({ clientSignOffId, campaign }) => {
  const [resendClientSignOffRequest] = useResendClientSignOffRequestMutation();

  const handleResend = (): Promise<boolean> => {
    return new Promise((resolve) => {
      if (clientSignOffId && campaign) {
        resendClientSignOffRequest({
          variables: {
            clientSignOffId: clientSignOffId,
            campaignId: campaign.campaignId,
          },
        })
          .then(() => {
            toast.success("Success", { description: "Email sent successfully" });
          })
          .catch((err) => {
            toast.error("Request failed", { description: err.message });
          })
          .finally(() => {
            resolve(true);
          });
      }
    });
  };

  return (
    <TailwindDialog type="info" title="Resend request" onConfirmCallback={() => handleResend()}>
      <p>
        Are you sure? You will be resending the client sign off email to <span className="font-semibold">{campaign.client.legalName}</span>
      </p>
    </TailwindDialog>
  );
};

export default ResendClientSignOffModal;

import { yupResolver } from "@hookform/resolvers/yup";
import MonetButton from "components/tailwind/MonetButton";
import MonetFileInput from "components/tailwind/form/MonetFileInput";
import MonetInput from "components/tailwind/form/MonetInput";
import TailwindSelectInput from "components/tailwind/headlessTailwind/TailwindSelectInput";
import { useSessionProvider } from "contexts/SessionProvider";
import getSymbolFromCurrency from "currency-symbol-map";
import React, { useMemo } from "react";
import { Controller, Resolver, useForm } from "react-hook-form";
import { toast } from "sonner";
import { getCurrencies } from "utils/currency";
import { processImageFile } from "utils/file";
import { useCompleteOrgProfileMutation } from "../graphql/mutations.generated";
import { OrganisationProfileFormObject, organisationProfileSchema } from "./ProfileStepValidation";

type ProfileStepProps = {
  handleNextStep: () => void;
};

const ProfileStep: React.FC<ProfileStepProps> = ({ handleNextStep }) => {
  const { partner, refetchCurrentPartner } = useSessionProvider();

  const [completeOrgProfile] = useCompleteOrgProfileMutation();

  const onSubmit = async (formData: OrganisationProfileFormObject) => {
    try {
      const logo = await processImageFile(formData.logo[0]);

      await completeOrgProfile({
        variables: {
          address: formData.address,
          functionalCurrencies: formData.functionalCurrencies,
          logo: {
            file: logo.filePath!,
            name: logo.name!,
            type: logo.type!,
          },
        },
      });

      await refetchCurrentPartner();
      handleNextStep();
    } catch (error: any) {
      console.log(error);
      toast.error("Request failed", { description: "We could not process the request. Please try again" });
    }
  };

  const methods = useForm<OrganisationProfileFormObject>({
    mode: "onTouched",
    resolver: yupResolver(organisationProfileSchema) as Resolver<OrganisationProfileFormObject>,
    defaultValues: {
      functionalCurrencies: [],
      logo: "",
      address: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        postcode: "",
      },
    },
  });

  const transactionCurrencies = useMemo(() => {
    return getCurrencies.map((item) => ({
      label: `${item.currency} (${getSymbolFromCurrency(item.currency)})`,
      value: item.currency,
    }));
  }, []);

  return (
    <div className="w-full text-sm flex flex-col justify-center items-center">
      <div className="max-w-[500px]">
        <div className="mb-10">
          <h2 className="text-xl font-semibold">Complete your organisation profile</h2>
          <p>Please provide the following information to complete your organisation profile.</p>
        </div>
        {/* Add your component content here */}
        <form className="flex flex-col gap-4" onSubmit={methods.handleSubmit(onSubmit)}>
          <MonetFileInput
            label="Company Logo"
            size="small"
            tooltip="Upload your company logo. The logo will be visible to talent and vendors."
            {...methods.register("logo")}
            disabled={methods.formState.isSubmitting}
            error={methods.formState.errors.logo?.message as string}
          />
          <Controller
            control={methods.control}
            name="functionalCurrencies"
            render={({ field: { ...inputProps } }) => (
              <TailwindSelectInput
                inputProps={inputProps}
                multiple={true}
                label="Operating Currencies"
                placeholder="Please select..."
                tooltip={`The currencies that ${partner?.name} use in daily operations. Only the currencies that MONET supports are listed`}
                options={transactionCurrencies}
                error={methods.formState.errors.functionalCurrencies?.message as string}
                disabled={methods.formState.isSubmitting}
              />
            )}
          />
          <div className="flex flex-col gap-2 w-full">
            <p className="font-semibold">Company address</p>
            <div className="flex flex-col md:flex-row gap-2">
              <MonetInput
                {...methods.register("address.addressLine1")}
                id="address.addressLine1"
                placeholder="Address line 1"
                error={methods.formState.errors.address?.addressLine1?.message as string}
                disabled={methods.formState.isSubmitting}
              />
              <MonetInput
                {...methods.register("address.addressLine2")}
                id="address.addressLine2"
                placeholder="Address line 2"
                error={methods.formState.errors.address?.addressLine2?.message as string}
                disabled={methods.formState.isSubmitting}
              />
            </div>
            <div className="flex flex-col md:flex-row gap-2">
              <MonetInput
                {...methods.register("address.city")}
                placeholder="City"
                error={methods.formState.errors.address?.city?.message as string}
                disabled={methods.formState.isSubmitting}
              />
              <MonetInput
                {...methods.register("address.postcode")}
                id="address.postcode"
                placeholder="Postcode"
                error={methods.formState.errors.address?.postcode?.message as string}
                disabled={methods.formState.isSubmitting}
              />
            </div>
          </div>
          <MonetButton type="submit" className="flex-1" loading={methods.formState.isLoading} disabled={methods.formState.isSubmitting || !methods.formState.isValid}>
            Next
          </MonetButton>
        </form>
      </div>
    </div>
  );
};

export default ProfileStep;

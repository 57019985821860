import { MonetBadgeVariants } from "components/tailwind/MonetBadge";
import { countries } from "countries-list";
import getSymbolFromCurrency from "currency-symbol-map";
import { alpha3ToAlpha2 } from "i18n-iso-countries";
import { Partner, PartnerSubscriptionStatus } from "shared/types/Partner";
import { EP3User, isInAdminContext } from "./User";
import { Country } from "./country";

export const industries = [
  "Influencer Marketing",
  "Modelling and Casting",
  "Talent Management",
  "Content",
  "Media",
  "PR and Comms",
  "Creative",
  "Social",
  "Affiliates",
  "Advertising",
  "Performance",
  "Production",
  "Other",
];

export const turnovers = [
  "0-500,000",
  "500,001-1,000,000",
  "1,000,001-5,000,000",
  "5,000,001-10,000,000",
  "10,000,001-15,000,000",
  "15,000,001-20,000,000",
  "20,000,001-30,000,000",
  "30,000,001-40,000,000",
  "40,000,001-50,000,000",
  "50,000,001+",
];

const formatTurnoverWithCurrency = (country: string, value: string) => {
  let currencySymbol = "";
  if (!value) {
    return "";
  }
  if (country) {
    const selectedCurrency = (countries as Country)[alpha3ToAlpha2(country) as string]?.currency;
    if (selectedCurrency) {
      currencySymbol = getSymbolFromCurrency(selectedCurrency) ?? "";
    }
  }

  const [lowerValue, upperValue] = value.split("-");
  const upperValueWithSymbol = upperValue ? `- ${currencySymbol}${upperValue}` : "";
  return `${currencySymbol}${lowerValue} ${upperValueWithSymbol}`;
};

export const getTurnoversForInput = (country: string) => {
  return turnovers.map((x) => {
    return { label: formatTurnoverWithCurrency(country, x), value: x };
  });
};

export const PartnerDefaultConfiguration: Partner["defaultConfiguration"] = {
  logoUrl: "",
  returnUrl: "",
  showLanding: false,
  termsAndConditions: {
    link: "",
    linkType: "Generic",
  },
  invoiceAssignmentTerms: {
    link: "",
    linkType: "Generic",
    earlyPayAgreement: "",
    standardTerms: "",
    onboardingAgreement: "",
  },
  payoutTtlDelta: 0,
  allowedCurrencies: undefined,
  feeSettings: {
    max: 0,
    default: 0,
  },
};

export const getPartnerKYBStatusSettings = (status: Partner["kybStatus"]): { color: MonetBadgeVariants["variant"] } => {
  switch (status) {
    case "NOT_VERIFIED":
      return {
        color: "gray",
      };
    case "VERIFIED":
      return {
        color: "green",
      };
    default:
      return {
        color: "gray",
      };
  }
};

export const getPartnerSubscriptionSettings = (status: PartnerSubscriptionStatus): { color: MonetBadgeVariants["variant"] } => {
  switch (status) {
    case "ACTIVE":
      return {
        color: "green",
      };
    case "TRIALING":
      return {
        color: "blue",
      };
    case "CANCELED":
    case "INACTIVE":
      return {
        color: "gray",
      };
    default:
      return {
        color: "gray",
      };
  }
};

export const getPartnerInitials = (partnerName: string) => {
  const [name1, name2] = partnerName.split(" ");
  return (name1.substring(0, 1) + (name2?.substring(0, 1) || "")).toUpperCase();
};

export const missingOrganisationProfileInformation = (partner: Partner) => {
  return [
    !partner?.name && "Name",
    !partner?.address && "Address",
    !partner?.businessMetadata?.companyWebsite && "Company website",
    !partner?.businessMetadata?.countryOfRegistration && "Country of registration",
    !partner?.businessMetadata?.functionalCurrencies && "Transaction currencies",
    !partner?.defaultConfiguration?.logoUrl && "Logo",
    !partner?.businessMetadata?.industry && "Industry",
    !partner?.businessMetadata?.revenue && "Revenue",
  ].filter(Boolean);
};

export const isPartnerProfileComplete = (partner?: Partner, user?: EP3User): boolean => {
  if (isInAdminContext(user!)) return true;
  if (!user || !partner) return false;

  return missingOrganisationProfileInformation(partner).length === 0;
};

export const isPartnerVerified = (partner?: Partner, user?: EP3User): boolean => {
  if (isInAdminContext(user!)) return true;
  return partner?.kybStatus === "VERIFIED";
};

export const isPartnerSubscribed = (partner?: Partner, user?: EP3User): boolean => {
  if (isInAdminContext(user!)) return true;
  return Boolean(partner?.subscription) && partner?.subscription?.status !== "INACTIVE";
};

export const restrictPlatformAccess = (partner?: Partner, user?: EP3User) => {
  if (!isPartnerSubscribed(partner, user)) {
    return "No active subscription";
  } else if (!isPartnerProfileComplete(partner, user)) {
    return "Please complete your organisation profile";
  } else return false;
};

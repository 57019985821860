import IntegrationsGraphic from "assets/tailwind/integrations.png";
import MonetButton from "components/tailwind/MonetButton";
import React, { useCallback, useState } from "react";
import { toast } from "sonner";
import { useCompleteOnboardingMutation, useCreateCodatCompanyMutation } from "../../graphql/mutations.generated";
import CodatModal from "./CodatModal";

type CodatStep = {
  refetchCurrentPartner: () => void;
};

const CodatStep: React.FC<CodatStep> = ({ refetchCurrentPartner }) => {
  const [codatCompanyId, setCodatCompanyId] = useState<undefined | string>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [createCodatCompany] = useCreateCodatCompanyMutation();
  const [completeOnboarding] = useCompleteOnboardingMutation();

  const createCompany = useCallback(async () => {
    setIsLoading(true);
    await createCodatCompany()
      .then((response) => {
        if (response.data) {
          setCodatCompanyId(JSON.parse(response.data.createCodatCompany).id);
          setIsModalOpen(true);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleFinish = useCallback(async () => {
    setIsModalOpen(false);
    setIsLoading(true);
    await completeOnboarding()
      .then((response) => {
        if (response.data) {
          toast.success("Onboarding completed successfully");
          refetchCurrentPartner();
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <div className="flex flex-col items-center justify-center h-full">
        <div className="flex flex-col items-center text-center space-y-2 max-w-[500px]">
          <img src={IntegrationsGraphic} alt="Integrations graphic" className="my-5 w-[200px]" />
          <h1 className="text-xl font-semibold">Sync your accounts</h1>
          <p className="text-sm">
            To access campaign financing, you’ll need to sync your accounting software. You can do this now or later by going to the Integrations section in your organization
            profile.
          </p>
          <div className="flex flex-col pt-2">
            <MonetButton onClick={createCompany} loading={isLoading} disabled={isLoading}>
              Sync accounts
            </MonetButton>
            <MonetButton variant="link" onClick={handleFinish} disabled={isLoading}>
              Skip step? You can come back to this later
            </MonetButton>
          </div>
        </div>
      </div>
      {codatCompanyId && <CodatModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} companyId={codatCompanyId} onFinish={handleFinish} />}
    </>
  );
};

export default CodatStep;

import { CodatLinkProps, initializeCodatLink } from "@codat/sdk-link-types";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { toast } from "sonner";

type CodatModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  companyId: string;
  onFinish: () => void;
};

const CodatModal: React.FC<CodatModalProps> = ({ isModalOpen, setIsModalOpen, companyId, onFinish }) => {
  const [componentMount, setComponentMount] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    const target = componentMount;

    if (companyId) {
      const props: CodatLinkProps = {
        companyId: companyId,
        onConnection: () => console.debug("Codat connection successful"),
        onError: (err) => toast.error(err.message),
        onClose: () => setIsModalOpen(false),
        onFinish: onFinish,
      };

      if (target && target.children.length === 0) {
        initializeCodatLink(target, props);
      }
    }
  }, [componentMount, companyId]);

  return (
    <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} className="relative z-50">
      <DialogBackdrop className="fixed inset-0 bg-black/30" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel className="h-full flex flex-col bg-white border shadow-sm overflow-auto rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
          <div className="w-[460px] h-[840px]" ref={setComponentMount}></div>
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default CodatModal;

import { Money } from "@monet-money/money-type";
import { ReactComponent as PlusIcon } from "assets/tailwind/icons/plus.svg";
import MonetButton from "components/tailwind/MonetButton";
import MonetCardTitle from "components/tailwind/MonetCardTitle";
import TailwindSelectInput from "components/tailwind/headlessTailwind/TailwindSelectInput";
import { useModal } from "contexts/ModalProvider";
import usePayoutProvider from "contexts/PayoutProvider";
import { useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Campaign } from "shared/types/Campaign";
import { Invoice } from "shared/types/Invoice";
import { Payee } from "shared/types/Payee";
import { Payout } from "shared/types/Payout";
import { enumToNiceString } from "utils/strings";
import AddPayeeDrawer from "./AddPayeeDrawer";
import ViewPayeeDetailsDrawer from "./ViewPayeeDetailsDrawer";

type PayoutDetailsStepProps = {
  refetchPayees(): void;
  payoutType: Payout["payoutType"];
  campaigns?: Campaign[];
  payees?: Payee[];
  invoices?: Invoice[];
  isInvoicesLoading?: boolean;
};

const PayoutDetailsStep: React.FC<PayoutDetailsStepProps> = ({ isInvoicesLoading, payoutType, campaigns, payees, invoices, refetchPayees }) => {
  const { setSelectedCampaign, setSelectedPayee, selectedPayee, setSelectedInvoice } = usePayoutProvider();
  const { openModal } = useModal();

  const {
    register,
    watch,
    formState: { errors },
    control,
    resetField,
  } = useFormContext();

  const selectedCampaign = watch("campaign");
  const selectedPayeeValue = watch("payee");
  const selectedInvoiceId = watch("invoiceId");

  useEffect(() => {
    if (payees && selectedPayeeValue) {
      const payee = payees.find((x) => x.payeeId === selectedPayeeValue);
      setSelectedPayee(payee);
    }
  }, [selectedPayeeValue, payees]);

  useEffect(() => {
    if (campaigns) {
      const campaign = campaigns.find((x) => x.campaignId === selectedCampaign);
      resetField("payoutRequest");
      resetField("invoiceId");
      setSelectedCampaign(campaign);
    }
  }, [selectedCampaign, campaigns]);

  useEffect(() => {
    if (invoices) {
      const invoice = invoices.find((x) => x.invoiceId === selectedInvoiceId);
      setSelectedInvoice(invoice);
    }
  }, [selectedInvoiceId, invoices]);

  const payeeSelectListOptions = useMemo(() => {
    if (payees) {
      return payees?.map((payee) => ({
        label: payee.payeeAlias,
        value: payee.payeeId,
      }));
    } else return [];
  }, [payees]);

  const campaignSelectListOptions = useMemo(() => {
    return campaigns?.map((campaign) => ({
      label: campaign.campaignName,
      value: campaign.campaignId,
    }));
  }, [campaigns]);

  const invoicesSelectListOptions = useMemo(() => {
    return invoices?.reduce((result: Array<{ label: string; value: string }>, invoice) => {
      if (invoice.funding?.fundingStatus === "ACTIVE") {
        result.push({
          label: `${invoice.invoiceNumber} (${Money.fromStorageType(invoice.invoiceValue).format(true, true)})`,
          value: invoice.invoiceId,
        });
      }
      return result;
    }, []);
  }, [invoices]);

  return (
    <>
      <div className="flex flex-col gap-6">
        <div>
          <MonetCardTitle>{enumToNiceString(payoutType)} payout</MonetCardTitle>
          <p className="text-sm">{payoutType === "VENDOR" ? "Make a payout to one of your vendors" : "Make a payout to your agency's bank account"}</p>
        </div>
        <div className="flex flex-col gap-4">
          <Controller
            control={control}
            name="campaign"
            render={({ field: { ...inputProps } }) => (
              <TailwindSelectInput
                inputProps={inputProps}
                placeholder="Please select a campaign..."
                error={errors.country?.message as string}
                label="Campaign"
                options={campaignSelectListOptions ?? []}
              />
            )}
          />
          <Controller
            control={control}
            name="invoiceId"
            render={({ field: { ...inputProps } }) => (
              <TailwindSelectInput
                inputProps={inputProps}
                placeholder="Please select a invoice..."
                error={errors.invoiceId?.message as string}
                disabled={isInvoicesLoading || !selectedCampaign}
                label="Invoice"
                tooltip="This is the invoice associated to the campaign which the payout will be linked to"
                options={invoicesSelectListOptions ?? []}
              />
            )}
          />
          <div className="flex flex-col w-full items-end gap-2">
            <Controller
              control={control}
              name="payee"
              render={({ field: { ...inputProps } }) => (
                <TailwindSelectInput
                  inputProps={inputProps}
                  placeholder={payoutType === "VENDOR" ? "Please select a vendor..." : "Please select a bank account..."}
                  error={errors.payee?.message as string}
                  label={payoutType === "VENDOR" ? "Vendor" : "Bank account"}
                  options={payeeSelectListOptions}
                  secondaryLabel={
                    selectedPayee && (
                      <MonetButton
                        variant="link"
                        size="fit-content"
                        className="text-xs font-normal"
                        onClick={() => openModal(ViewPayeeDetailsDrawer, { payoutType: payoutType, payee: selectedPayee })}
                      >
                        View {payoutType === "VENDOR" ? "vendor" : "bank account"} details
                      </MonetButton>
                    )
                  }
                />
              )}
            />
            <MonetButton variant="outlined" size="x-small" onClick={() => openModal(AddPayeeDrawer, { payoutType: payoutType, refetchPayees: refetchPayees })}>
              <PlusIcon />
              Create new {payoutType === "VENDOR" ? "vendor" : "bank account"}
            </MonetButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default PayoutDetailsStep;

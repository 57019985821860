import { VariantProps, cva } from "class-variance-authority";
import clsx from "clsx";
import { InputHTMLAttributes, forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import MonetToolTip from "../MonetTooltip";

export type MonetFileInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, "size"> &
  VariantProps<typeof variants> & {
    label?: string;
    error?: string;
    className?: string;
    tooltip?: string;
  };

const MonetFileInput: React.FC<MonetFileInputProps> = forwardRef<HTMLInputElement, MonetFileInputProps>(({ error, variant, size, className, label, tooltip, ...props }, ref) => {
  const errorStyling = error ? "border-red-500 ring ring-red-100 ring-offset-0 focus:ring-red-500 focus:ring focus:ring-red-100 focus:outline-offset-0" : undefined;

  return (
    <div className="flex flex-col gap-2 w-full">
      {label && (
        <span className="block text-sm font-semibold text-gray-800 dark:text-neutral-300">
          {label}
          {tooltip && <MonetToolTip text={tooltip} />}
        </span>
      )}
      <div>
        <label htmlFor="file-input" className="sr-only">
          Choose file
        </label>
        <input ref={ref} accept=".png,.jpg,.jpeg,.pdf,.docx,.doc" type="file" id="file-input" className={twMerge(clsx(variants({ variant, size }), errorStyling))} {...props} />
        {error && <p className="text-sm text-red-500 mt-2">{error}</p>}
      </div>
    </div>
  );
});

export default MonetFileInput;

const variants = cva(
  "block w-full text-sm border rounded-lg hover:cursor-pointer disabled:opacity-70 disabled:pointer-events-none disabled:bg-gray-100 dark:bg-transparent dark:disabled:bg-transparent file:bg-gray-50 file:border-0 file:me-4 dark:file:bg-neutral-700 dark:file:text-neutral-400",
  {
    variants: {
      variant: {
        default:
          "border-gray-200 placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600",
      },
      size: {
        small: "file:py-2 file:px-3.5",
        default: "file:py-3.5 file:px-5",
        large: "file:py-6 file:px-5",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams } from "@mui/x-data-grid";
import { ReactComponent as DeleteIcon } from "assets/tailwind/icons/trash-2.svg";
import CustomDataGrid from "components/CustomDataGrid/CustomDataGrid";
import MonetDropdownMenuItem from "components/tailwind/MonetDropdownMenuItem";
import MonetMenuDropdown from "components/tailwind/MonetMenuDropdown";
import SignedUrl from "components/tailwind/SignedUrl";
import StatusBadge from "components/tailwind/StatusBadge";
import { useModal } from "contexts/ModalProvider";
import { useSessionProvider } from "contexts/SessionProvider";
import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import { DEFAULT_DATE_FORMAT } from "shared/constants/Constants";
import { Campaign, CampaignDeliverable, ClientSignOff } from "shared/types/Campaign";
import { isInAdminContext, isInPartnerContext } from "utils/User";
import DeliverablesModal from "../../fundingTab/invoiceSection/DeliverablesModal";
import DeleteClientSignOffModal from "./DeleteClientSignOffModal";
import FeedbackModal from "./FeedbackModal";
import ResendClientSignOffModal from "./ResentClientSignOffModal";

type ClientSignOffTableProps = {
  refetchCampaign(): void;
  campaign?: Campaign;
};

const ClientSignOffTable: React.FC<ClientSignOffTableProps> = ({ campaign, refetchCampaign }) => {
  const { openModal } = useModal();
  const { user } = useSessionProvider();

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    id: isInAdminContext(user),
    delete: isInPartnerContext(user),
  });

  const clientSignOffs = useMemo(() => {
    if (!campaign || !campaign.clientSignOff) {
      return [];
    }

    return campaign.clientSignOff;
  }, [campaign]);

  const signOffDeliverables = (deliverableIds: number[]) => {
    if (campaign) {
      return deliverableIds.reduce((result: CampaignDeliverable[], deliverableId) => {
        const deliverable = campaign.deliverables?.find((deliverable) => deliverable.deliverableId === deliverableId);
        if (deliverable) {
          result.push(deliverable);
        }
        return result;
      }, []);
    } else return undefined;
  };

  const columns: GridColDef[] = [
    {
      type: "actions",
      field: "actions",
      width: 50,
      renderCell: (params: GridRenderCellParams<any, ClientSignOff, any>) => {
        if (campaign?.campaignStatus !== "COMPLETED" && !isInAdminContext(user)) {
          return (
            <MonetMenuDropdown
              menuButtonProps={{
                variant: "white",
              }}
              dropdownPosition="bottom start"
              footerChildren={
                <MonetDropdownMenuItem
                  variant="danger"
                  icon={<DeleteIcon />}
                  onClick={() => openModal(DeleteClientSignOffModal, { campaignId: campaign!.campaignId, refetchCampaign: refetchCampaign, clientSignOffId: params.row.id })}
                >
                  Delete
                </MonetDropdownMenuItem>
              }
            >
              {params.row.status === "PENDING" && (
                <MonetDropdownMenuItem onClick={() => openModal(ResendClientSignOffModal, { campaign: campaign!, clientSignOffId: params.row.id })}>
                  Resend sign off
                </MonetDropdownMenuItem>
              )}
            </MonetMenuDropdown>
          );
        } else return null;
      },
    },
    {
      field: "id",
      headerName: "ID",
      type: "string",
      minWidth: 125,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      type: "string",
      minWidth: 125,
      flex: 1,
      renderCell: (params: GridRenderCellParams<ClientSignOff>) => {
        return <StatusBadge type="clientSignOff" text={params.row.status} />;
      },
    },
    {
      field: "deliverables",
      type: "actions",
      headerName: "Deliverables",
      minWidth: 100,
      flex: 1,
      renderCell: (params: GridRenderCellParams<ClientSignOff>) => <DeliverablesModal deliverables={signOffDeliverables(params.row.deliverables)} />,
    },
    {
      field: "signOffDocument",
      type: "string",
      headerName: "Document",
      minWidth: 100,
      flex: 1,
      renderCell: (params: GridRenderCellParams<ClientSignOff>) => <SignedUrl url={params.row.signOffDocument} />,
    },
    {
      field: "reviewer.name",
      headerName: "Client contact name",
      type: "string",
      minWidth: 125,
      flex: 1,
      valueGetter: (value, row) => {
        return row.reviewer.name;
      },
    },
    {
      field: "reviewer.email",
      headerName: "Client contact email",
      type: "string",
      minWidth: 125,
      flex: 1,
      valueGetter: (value, row) => {
        return row.reviewer.email;
      },
    },
    {
      field: "feedback",
      headerName: "Client feedback",
      type: "actions",
      minWidth: 125,
      flex: 1,
      renderCell: (params: GridRenderCellParams<ClientSignOff>) => <FeedbackModal feedback={params.row.feedback} />,
    },
    {
      field: "url",
      headerName: "Client sign off URL",
      type: "string",
      minWidth: 125,
      flex: 1,
      valueFormatter: (value, row) => value ?? "-",
    },
    {
      field: "reviewedAt",
      headerName: "Reviewed date",
      type: "string",
      minWidth: 125,
      flex: 1,
      valueFormatter: (value, row) => (value ? dayjs(value).format(DEFAULT_DATE_FORMAT) : "-"),
    },
    {
      field: "createdAt",
      headerName: "Created date",
      type: "string",
      minWidth: 125,
      flex: 1,
      valueFormatter: (value, row) => dayjs(value).format(DEFAULT_DATE_FORMAT),
    },
  ];

  return (
    <CustomDataGrid
      rows={clientSignOffs}
      columns={columns}
      enableExport={false}
      showAsUnstyledCard={true}
      initialState={{
        sorting: {
          sortModel: [{ field: "createdAt", sort: "desc" }],
        },
      }}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
      getRowId={(row: ClientSignOff) => row.id}
      sx={{
        height: 300,
      }}
    />
  );
};

export default ClientSignOffTable;

import { yupResolver } from "@hookform/resolvers/yup";
import MonetBorderedCardContent from "components/tailwind/MonetBorderedCardContent";
import MonetDrawerButtons from "components/tailwind/MonetDrawerButtons";
import MonetLabelledItem from "components/tailwind/MonetLabelledItem";
import MonetCheckbox from "components/tailwind/form/MonetCheckbox";
import MonetSwitch from "components/tailwind/form/MonetSwitch";
import { useModal } from "contexts/ModalProvider";
import { useEffect, useMemo, useState } from "react";
import { Resolver, useForm } from "react-hook-form";
import { Campaign } from "shared/types/Campaign";
import { toast } from "sonner";
import { useCreateClientSignOffRequestMutation } from "views/campaigns/graphql/mutations.generated";
import { ClientSignOffFormObject, clientSignOffSchema } from "views/campaigns/validationSchema/CreateClientSignOffSchema";

type RequestClientSignOffProps = {
  refetchCampaign(): void;
  campaign?: Campaign;
  onBackCallback: React.Dispatch<React.SetStateAction<number | undefined>>;
};

const RequestClientSignOffDrawer: React.FC<RequestClientSignOffProps> = ({ refetchCampaign, campaign, onBackCallback }) => {
  const [createClientSignOffRequest] = useCreateClientSignOffRequestMutation();
  const [selectedDeliverables, setSelectedDeliverables] = useState<number[] | undefined>();
  const { closeModal } = useModal();

  const onSubmit = async (values: ClientSignOffFormObject) => {
    if (campaign) {
      try {
        const signOffId = Math.floor(Date.now() / 1000);
        await createClientSignOffRequest({
          variables: {
            deliverables: values.deliverables,
            campaignId: campaign.campaignId,
            signOffId: signOffId
          },
        }).then(() => {
          refetchCampaign();
          closeModal();
          toast.success("The sign off request has been sent to the client");
        });
      } catch (error: any) {
        toast.error("Request failed", { description: error.message });
      }
    }
  };

  const {
    formState: { isSubmitting, isValid, errors },
    handleSubmit,
    setValue,
    register,
  } = useForm<ClientSignOffFormObject>({
    mode: "onTouched",
    defaultValues: {
      deliverables: [],
    },
    resolver: yupResolver(clientSignOffSchema) as Resolver<ClientSignOffFormObject>,
  });

  const handleDeliverableSelection = (deliverableId: number) => {
    setSelectedDeliverables((prevSelectedDeliverables) => {
      if (!prevSelectedDeliverables) {
        return [deliverableId];
      } else if (prevSelectedDeliverables.includes(deliverableId)) {
        return prevSelectedDeliverables.filter((id) => id !== deliverableId);
      } else {
        return [...prevSelectedDeliverables, deliverableId];
      }
    });
  };

  const filteredDeliverables = useMemo(() => {
    if (!campaign || !campaign.deliverables) {
      return [];
    }

    return campaign.deliverables.filter((deliverable) => deliverable.status === "NOT_COMPLETED");
  }, [campaign]);

  const deliverables = useMemo(() => {
    if (!campaign || !campaign.deliverables) {
      return null;
    }

    if (filteredDeliverables.length === 0) {
      return <p className="text-sm text-center">No deliverables</p>;
    }

    return filteredDeliverables.map((deliverable) => (
      <MonetSwitch
        key={deliverable.deliverableId}
        label={deliverable.name}
        showInRow={true}
        showLabels={false}
        size="small"
        onClick={() => handleDeliverableSelection(deliverable.deliverableId)}
        checked={selectedDeliverables?.includes(deliverable.deliverableId)}
      />
    ));
  }, [filteredDeliverables, selectedDeliverables]);

  useEffect(() => {
    if (selectedDeliverables) {
      setValue("deliverables", selectedDeliverables, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
    }
  }, [selectedDeliverables]);

  return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col flex-1 justify-between mt-2 gap-6">
          <div className="flex flex-col gap-2">
            <div>
              <p className="text-sm font-semibold">Client contact</p>
              <p className="text-sm">The request will be sent to the assigned contact. Update details via the main campaign screen if needed.</p>
            </div>
            <MonetBorderedCardContent>
              <MonetLabelledItem label="Name" value={campaign?.client.contact?.name} />
              <MonetLabelledItem label="Email" value={campaign?.client.contact?.email} />
              <MonetLabelledItem label="Client" value={campaign?.client.legalName ?? "-"} />
            </MonetBorderedCardContent>
            <div>
              <p className="text-sm font-semibold">Deliverables</p>
              <p className="text-sm">Select the deliverables to include. The client will see the name, description, and links. Ensure all details are correct before sending.</p>
            </div>
            <ul className="flex flex-col sm:gap-1 mb-5">
              <MonetBorderedCardContent className="max-h-[150px] overflow-auto mt-2">{deliverables}</MonetBorderedCardContent>
              <p className="text-sm text-red-500 mt-2">{errors.deliverables?.message as string}</p>
            </ul>
          </div>
          <div>
            <MonetCheckbox label="Confirmation" {...register("confirmation")} id="termsAndConditions" className="mr-2 mt-1 self-start">
              By proceeding, you agree that your client will receive an email from you requesting sign-off via MONET.
            </MonetCheckbox>
            <MonetDrawerButtons cancelBtnText="Back" onCancel={() => onBackCallback(undefined)}  cancelDisabled={isSubmitting} submitDisabled={isSubmitting || !isValid} loading={isSubmitting} />
          </div>
        </form>
  );
};

export default RequestClientSignOffDrawer;

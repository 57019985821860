import { ReactComponent as CrossIcon } from "assets/tailwind/icons/cross.svg";
import { useStripeFulfillCheckoutMutation } from "components/onboardingStepper/graphql/mutations.generated";
import MonetAlert from "components/tailwind/MonetAlert";
import MonetLoadingSpinner from "components/tailwind/MonetLoadingSpinner";
import { useSessionProvider } from "contexts/SessionProvider";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import StripePricingTable from "./StripePricingTable";

type SubscriptionsStepProps = {
  handleNextStep: () => void;
};

const SubscriptionsStep: React.FC<SubscriptionsStepProps> = ({ handleNextStep }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const [fulfillCheckout] = useStripeFulfillCheckoutMutation();

  const { refetchCurrentPartner, partner } = useSessionProvider();
  const { search } = useLocation();

  const stripeSessionId = new URLSearchParams(search).get("stripeSessionId");

  useEffect(() => {
    if (stripeSessionId) {
      setIsLoading(true);
      fulfillCheckout({
        variables: {
          stripeSessionId: stripeSessionId,
        },
      })
        .then(async () => {
          await refetchCurrentPartner();
          handleNextStep();
        })
        .catch((err) => {
          setError(err.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);
  return (
    <div>
      {isLoading ? (
        <div className="flex flex-col items-center">
          <MonetLoadingSpinner size="large" />
          <p>Processing request</p>
        </div>
      ) : error ? (
        <div className="flex flex-col items-center gap-2">
          <div className="rounded-full p-2 bg-red-50">
            <div className="rounded-full p-4 bg-red-100 text-red-600">
              <CrossIcon width={24} height={24} />
            </div>
          </div>
          <h1 className="text-2xl font-light text-gray-800 dark:text-white text-center">Failed to activate subscription</h1>
          <MonetAlert variant="error" message={error} />
          <p className="text-xs text-center max-w-[500px]">Please contact support@monet.money quoting partner ID: {partner?.partnerId}</p>
        </div>
      ) : (
        <>
          <div className="flex flex-col gap-2 items-center mb-8">
            <p className="font-semibold text-lg">Platform subscription</p>
            <p className="text-sm text-center max-w-[700px]">
              Get started and select the subscription that works best for you and your business. Enjoy a 14-day free trial with each plan, and if you decide to cancel before the
              trial ends, you will not be charged.
            </p>
          </div>
          <StripePricingTable />
        </>
      )}
    </div>
  );
};

export default SubscriptionsStep;

import * as Types from 'shared/generated/ep3.graphql.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateCampaignMutationVariables = Types.Exact<{
  campaign: Types.CampaignInput;
}>;


export type CreateCampaignMutation = { __typename?: 'Mutation', createCampaign: { __typename?: 'CreateCampaignResponse', campaignId: string, partnerId: string } };

export type UpdateCampaignMutationVariables = Types.Exact<{
  campaign: Types.UpdateCampaignInput;
}>;


export type UpdateCampaignMutation = { __typename?: 'Mutation', updateCampaign: any };

export type RemoveCampaignDocumentMutationVariables = Types.Exact<{
  file: Types.Scalars['String']['input'];
  campaignId: Types.Scalars['String']['input'];
}>;


export type RemoveCampaignDocumentMutation = { __typename?: 'Mutation', removeCampaignDocument: any };

export type UpdateCampaignStatusMutationVariables = Types.Exact<{
  partnerId: Types.Scalars['String']['input'];
  campaignId: Types.Scalars['String']['input'];
  campaignStatus: Types.Scalars['String']['input'];
}>;


export type UpdateCampaignStatusMutation = { __typename?: 'Mutation', updateCampaignStatus: any };

export type ExtendCampaignDueDateMutationVariables = Types.Exact<{
  partnerId: Types.Scalars['String']['input'];
  campaignId: Types.Scalars['String']['input'];
  dueDate: Types.Scalars['String']['input'];
  comment?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ExtendCampaignDueDateMutation = { __typename?: 'Mutation', extendCampaignDueDate: boolean };

export type GenerateCampaignDocumentUploadUrlMutationVariables = Types.Exact<{
  category: Types.DocumentCategory;
  fileName: Types.Scalars['String']['input'];
  ignoreExpireTag?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GenerateCampaignDocumentUploadUrlMutation = { __typename?: 'Mutation', generateDocumentUploadUrl: string };

export type DeleteCampaignMutationVariables = Types.Exact<{
  campaignId: Types.Scalars['String']['input'];
  partnerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type DeleteCampaignMutation = { __typename?: 'Mutation', deleteCampaign: any };

export type UploadCampaignDocumentsMutationVariables = Types.Exact<{
  partnerId: Types.Scalars['String']['input'];
  campaignId: Types.Scalars['String']['input'];
  documents: Array<Types.CampaignDocumentsUpload> | Types.CampaignDocumentsUpload;
}>;


export type UploadCampaignDocumentsMutation = { __typename?: 'Mutation', uploadCampaignDocuments: boolean };

export type SetCampaignDueDateMutationVariables = Types.Exact<{
  partnerId: Types.Scalars['String']['input'];
  campaignId: Types.Scalars['String']['input'];
  dueDate: Types.Scalars['String']['input'];
}>;


export type SetCampaignDueDateMutation = { __typename?: 'Mutation', setCampaignDueDate: boolean };

export type UpdateClientMutationVariables = Types.Exact<{
  client: Types.UpdateClientInput;
  campaignId: Types.Scalars['String']['input'];
}>;


export type UpdateClientMutation = { __typename?: 'Mutation', updateClient: boolean };

export type UpdateCampaignDeliverablesMutationVariables = Types.Exact<{
  deliverables: Types.UpdateDeliverablesInput;
  campaignId: Types.Scalars['String']['input'];
}>;


export type UpdateCampaignDeliverablesMutation = { __typename?: 'Mutation', updateCampaignDeliverables: boolean };

export type DeleteCampaignDeliverableMutationVariables = Types.Exact<{
  deliverableId: Types.Scalars['Int']['input'];
  campaignId: Types.Scalars['String']['input'];
}>;


export type DeleteCampaignDeliverableMutation = { __typename?: 'Mutation', deleteCampaignDeliverable: boolean };

export type CreateInvoiceMutationVariables = Types.Exact<{
  invoice: Types.CreateInvoiceInput;
  campaignId: Types.Scalars['String']['input'];
}>;


export type CreateInvoiceMutation = { __typename?: 'Mutation', createInvoice: boolean };

export type DeleteInvoiceMutationVariables = Types.Exact<{
  invoiceId: Types.Scalars['String']['input'];
  campaignId: Types.Scalars['String']['input'];
}>;


export type DeleteInvoiceMutation = { __typename?: 'Mutation', deleteInvoice: boolean };

export type UpdateInvoiceMutationVariables = Types.Exact<{
  invoice: Types.UpdateInvoiceInput;
  campaignId: Types.Scalars['String']['input'];
}>;


export type UpdateInvoiceMutation = { __typename?: 'Mutation', updateInvoice: boolean };

export type RequestInvoiceFundingMutationVariables = Types.Exact<{
  invoiceId: Types.Scalars['String']['input'];
  campaignId: Types.Scalars['String']['input'];
}>;


export type RequestInvoiceFundingMutation = { __typename?: 'Mutation', requestInvoiceFunding: boolean };

export type UnderwriteInvoiceMutationVariables = Types.Exact<{
  invoice: Types.UnderwriteInvoiceInput;
  campaignId: Types.Scalars['String']['input'];
}>;


export type UnderwriteInvoiceMutation = { __typename?: 'Mutation', underwriteInvoice: boolean };

export type SettleInvoiceMutationVariables = Types.Exact<{
  invoice: Types.SettleInvoiceInput;
  campaignId: Types.Scalars['String']['input'];
  partnerId: Types.Scalars['String']['input'];
}>;


export type SettleInvoiceMutation = { __typename?: 'Mutation', settleInvoice: boolean };

export type CreateClientSignOffRequestMutationVariables = Types.Exact<{
  deliverables: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
  campaignId: Types.Scalars['String']['input'];
  signOffId: Types.Scalars['Int']['input'];
  clientSignOffDocument?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type CreateClientSignOffRequestMutation = { __typename?: 'Mutation', createClientSignOffRequest: boolean };

export type DeleteClientSignOffMutationVariables = Types.Exact<{
  clientSignOffId: Types.Scalars['Int']['input'];
  campaignId: Types.Scalars['String']['input'];
}>;


export type DeleteClientSignOffMutation = { __typename?: 'Mutation', deleteClientSignOff: boolean };

export type CompleteCampaignMutationVariables = Types.Exact<{
  campaignId: Types.Scalars['String']['input'];
  partnerId: Types.Scalars['String']['input'];
}>;


export type CompleteCampaignMutation = { __typename?: 'Mutation', completeCampaign: boolean };

export type ResendClientSignOffRequestMutationVariables = Types.Exact<{
  campaignId: Types.Scalars['String']['input'];
  clientSignOffId: Types.Scalars['Int']['input'];
}>;


export type ResendClientSignOffRequestMutation = { __typename?: 'Mutation', resendClientSignOffRequest: boolean };


export const CreateCampaignDocument = gql`
    mutation CreateCampaign($campaign: CampaignInput!) {
  createCampaign(campaign: $campaign) {
    campaignId
    partnerId
  }
}
    `;
export type CreateCampaignMutationFn = Apollo.MutationFunction<CreateCampaignMutation, CreateCampaignMutationVariables>;

/**
 * __useCreateCampaignMutation__
 *
 * To run a mutation, you first call `useCreateCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCampaignMutation, { data, loading, error }] = useCreateCampaignMutation({
 *   variables: {
 *      campaign: // value for 'campaign'
 *   },
 * });
 */
export function useCreateCampaignMutation(baseOptions?: Apollo.MutationHookOptions<CreateCampaignMutation, CreateCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCampaignMutation, CreateCampaignMutationVariables>(CreateCampaignDocument, options);
      }
export type CreateCampaignMutationHookResult = ReturnType<typeof useCreateCampaignMutation>;
export type CreateCampaignMutationResult = Apollo.MutationResult<CreateCampaignMutation>;
export type CreateCampaignMutationOptions = Apollo.BaseMutationOptions<CreateCampaignMutation, CreateCampaignMutationVariables>;
export const UpdateCampaignDocument = gql`
    mutation updateCampaign($campaign: UpdateCampaignInput!) {
  updateCampaign(campaign: $campaign)
}
    `;
export type UpdateCampaignMutationFn = Apollo.MutationFunction<UpdateCampaignMutation, UpdateCampaignMutationVariables>;

/**
 * __useUpdateCampaignMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignMutation, { data, loading, error }] = useUpdateCampaignMutation({
 *   variables: {
 *      campaign: // value for 'campaign'
 *   },
 * });
 */
export function useUpdateCampaignMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCampaignMutation, UpdateCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCampaignMutation, UpdateCampaignMutationVariables>(UpdateCampaignDocument, options);
      }
export type UpdateCampaignMutationHookResult = ReturnType<typeof useUpdateCampaignMutation>;
export type UpdateCampaignMutationResult = Apollo.MutationResult<UpdateCampaignMutation>;
export type UpdateCampaignMutationOptions = Apollo.BaseMutationOptions<UpdateCampaignMutation, UpdateCampaignMutationVariables>;
export const RemoveCampaignDocumentDocument = gql`
    mutation removeCampaignDocument($file: String!, $campaignId: String!) {
  removeCampaignDocument(file: $file, campaignId: $campaignId)
}
    `;
export type RemoveCampaignDocumentMutationFn = Apollo.MutationFunction<RemoveCampaignDocumentMutation, RemoveCampaignDocumentMutationVariables>;

/**
 * __useRemoveCampaignDocumentMutation__
 *
 * To run a mutation, you first call `useRemoveCampaignDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCampaignDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCampaignDocumentMutation, { data, loading, error }] = useRemoveCampaignDocumentMutation({
 *   variables: {
 *      file: // value for 'file'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useRemoveCampaignDocumentMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCampaignDocumentMutation, RemoveCampaignDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCampaignDocumentMutation, RemoveCampaignDocumentMutationVariables>(RemoveCampaignDocumentDocument, options);
      }
export type RemoveCampaignDocumentMutationHookResult = ReturnType<typeof useRemoveCampaignDocumentMutation>;
export type RemoveCampaignDocumentMutationResult = Apollo.MutationResult<RemoveCampaignDocumentMutation>;
export type RemoveCampaignDocumentMutationOptions = Apollo.BaseMutationOptions<RemoveCampaignDocumentMutation, RemoveCampaignDocumentMutationVariables>;
export const UpdateCampaignStatusDocument = gql`
    mutation updateCampaignStatus($partnerId: String!, $campaignId: String!, $campaignStatus: String!) {
  updateCampaignStatus(
    partnerId: $partnerId
    campaignId: $campaignId
    campaignStatus: $campaignStatus
  )
}
    `;
export type UpdateCampaignStatusMutationFn = Apollo.MutationFunction<UpdateCampaignStatusMutation, UpdateCampaignStatusMutationVariables>;

/**
 * __useUpdateCampaignStatusMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignStatusMutation, { data, loading, error }] = useUpdateCampaignStatusMutation({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *      campaignId: // value for 'campaignId'
 *      campaignStatus: // value for 'campaignStatus'
 *   },
 * });
 */
export function useUpdateCampaignStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCampaignStatusMutation, UpdateCampaignStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCampaignStatusMutation, UpdateCampaignStatusMutationVariables>(UpdateCampaignStatusDocument, options);
      }
export type UpdateCampaignStatusMutationHookResult = ReturnType<typeof useUpdateCampaignStatusMutation>;
export type UpdateCampaignStatusMutationResult = Apollo.MutationResult<UpdateCampaignStatusMutation>;
export type UpdateCampaignStatusMutationOptions = Apollo.BaseMutationOptions<UpdateCampaignStatusMutation, UpdateCampaignStatusMutationVariables>;
export const ExtendCampaignDueDateDocument = gql`
    mutation extendCampaignDueDate($partnerId: String!, $campaignId: String!, $dueDate: String!, $comment: String) {
  extendCampaignDueDate(
    partnerId: $partnerId
    campaignId: $campaignId
    dueDate: $dueDate
    comment: $comment
  )
}
    `;
export type ExtendCampaignDueDateMutationFn = Apollo.MutationFunction<ExtendCampaignDueDateMutation, ExtendCampaignDueDateMutationVariables>;

/**
 * __useExtendCampaignDueDateMutation__
 *
 * To run a mutation, you first call `useExtendCampaignDueDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExtendCampaignDueDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [extendCampaignDueDateMutation, { data, loading, error }] = useExtendCampaignDueDateMutation({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *      campaignId: // value for 'campaignId'
 *      dueDate: // value for 'dueDate'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useExtendCampaignDueDateMutation(baseOptions?: Apollo.MutationHookOptions<ExtendCampaignDueDateMutation, ExtendCampaignDueDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExtendCampaignDueDateMutation, ExtendCampaignDueDateMutationVariables>(ExtendCampaignDueDateDocument, options);
      }
export type ExtendCampaignDueDateMutationHookResult = ReturnType<typeof useExtendCampaignDueDateMutation>;
export type ExtendCampaignDueDateMutationResult = Apollo.MutationResult<ExtendCampaignDueDateMutation>;
export type ExtendCampaignDueDateMutationOptions = Apollo.BaseMutationOptions<ExtendCampaignDueDateMutation, ExtendCampaignDueDateMutationVariables>;
export const GenerateCampaignDocumentUploadUrlDocument = gql`
    mutation generateCampaignDocumentUploadUrl($category: DocumentCategory!, $fileName: String!, $ignoreExpireTag: Boolean) {
  generateDocumentUploadUrl(
    fileName: $fileName
    category: $category
    ignoreExpireTag: $ignoreExpireTag
  )
}
    `;
export type GenerateCampaignDocumentUploadUrlMutationFn = Apollo.MutationFunction<GenerateCampaignDocumentUploadUrlMutation, GenerateCampaignDocumentUploadUrlMutationVariables>;

/**
 * __useGenerateCampaignDocumentUploadUrlMutation__
 *
 * To run a mutation, you first call `useGenerateCampaignDocumentUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateCampaignDocumentUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateCampaignDocumentUploadUrlMutation, { data, loading, error }] = useGenerateCampaignDocumentUploadUrlMutation({
 *   variables: {
 *      category: // value for 'category'
 *      fileName: // value for 'fileName'
 *      ignoreExpireTag: // value for 'ignoreExpireTag'
 *   },
 * });
 */
export function useGenerateCampaignDocumentUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<GenerateCampaignDocumentUploadUrlMutation, GenerateCampaignDocumentUploadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateCampaignDocumentUploadUrlMutation, GenerateCampaignDocumentUploadUrlMutationVariables>(GenerateCampaignDocumentUploadUrlDocument, options);
      }
export type GenerateCampaignDocumentUploadUrlMutationHookResult = ReturnType<typeof useGenerateCampaignDocumentUploadUrlMutation>;
export type GenerateCampaignDocumentUploadUrlMutationResult = Apollo.MutationResult<GenerateCampaignDocumentUploadUrlMutation>;
export type GenerateCampaignDocumentUploadUrlMutationOptions = Apollo.BaseMutationOptions<GenerateCampaignDocumentUploadUrlMutation, GenerateCampaignDocumentUploadUrlMutationVariables>;
export const DeleteCampaignDocument = gql`
    mutation deleteCampaign($campaignId: String!, $partnerId: String) {
  deleteCampaign(campaignId: $campaignId, partnerId: $partnerId)
}
    `;
export type DeleteCampaignMutationFn = Apollo.MutationFunction<DeleteCampaignMutation, DeleteCampaignMutationVariables>;

/**
 * __useDeleteCampaignMutation__
 *
 * To run a mutation, you first call `useDeleteCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCampaignMutation, { data, loading, error }] = useDeleteCampaignMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useDeleteCampaignMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCampaignMutation, DeleteCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCampaignMutation, DeleteCampaignMutationVariables>(DeleteCampaignDocument, options);
      }
export type DeleteCampaignMutationHookResult = ReturnType<typeof useDeleteCampaignMutation>;
export type DeleteCampaignMutationResult = Apollo.MutationResult<DeleteCampaignMutation>;
export type DeleteCampaignMutationOptions = Apollo.BaseMutationOptions<DeleteCampaignMutation, DeleteCampaignMutationVariables>;
export const UploadCampaignDocumentsDocument = gql`
    mutation uploadCampaignDocuments($partnerId: String!, $campaignId: String!, $documents: [CampaignDocumentsUpload!]!) {
  uploadCampaignDocuments(
    campaignId: $campaignId
    partnerId: $partnerId
    documents: $documents
  )
}
    `;
export type UploadCampaignDocumentsMutationFn = Apollo.MutationFunction<UploadCampaignDocumentsMutation, UploadCampaignDocumentsMutationVariables>;

/**
 * __useUploadCampaignDocumentsMutation__
 *
 * To run a mutation, you first call `useUploadCampaignDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCampaignDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCampaignDocumentsMutation, { data, loading, error }] = useUploadCampaignDocumentsMutation({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *      campaignId: // value for 'campaignId'
 *      documents: // value for 'documents'
 *   },
 * });
 */
export function useUploadCampaignDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<UploadCampaignDocumentsMutation, UploadCampaignDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadCampaignDocumentsMutation, UploadCampaignDocumentsMutationVariables>(UploadCampaignDocumentsDocument, options);
      }
export type UploadCampaignDocumentsMutationHookResult = ReturnType<typeof useUploadCampaignDocumentsMutation>;
export type UploadCampaignDocumentsMutationResult = Apollo.MutationResult<UploadCampaignDocumentsMutation>;
export type UploadCampaignDocumentsMutationOptions = Apollo.BaseMutationOptions<UploadCampaignDocumentsMutation, UploadCampaignDocumentsMutationVariables>;
export const SetCampaignDueDateDocument = gql`
    mutation setCampaignDueDate($partnerId: String!, $campaignId: String!, $dueDate: String!) {
  setCampaignDueDate(
    campaignId: $campaignId
    partnerId: $partnerId
    dueDate: $dueDate
  )
}
    `;
export type SetCampaignDueDateMutationFn = Apollo.MutationFunction<SetCampaignDueDateMutation, SetCampaignDueDateMutationVariables>;

/**
 * __useSetCampaignDueDateMutation__
 *
 * To run a mutation, you first call `useSetCampaignDueDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCampaignDueDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCampaignDueDateMutation, { data, loading, error }] = useSetCampaignDueDateMutation({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *      campaignId: // value for 'campaignId'
 *      dueDate: // value for 'dueDate'
 *   },
 * });
 */
export function useSetCampaignDueDateMutation(baseOptions?: Apollo.MutationHookOptions<SetCampaignDueDateMutation, SetCampaignDueDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCampaignDueDateMutation, SetCampaignDueDateMutationVariables>(SetCampaignDueDateDocument, options);
      }
export type SetCampaignDueDateMutationHookResult = ReturnType<typeof useSetCampaignDueDateMutation>;
export type SetCampaignDueDateMutationResult = Apollo.MutationResult<SetCampaignDueDateMutation>;
export type SetCampaignDueDateMutationOptions = Apollo.BaseMutationOptions<SetCampaignDueDateMutation, SetCampaignDueDateMutationVariables>;
export const UpdateClientDocument = gql`
    mutation updateClient($client: UpdateClientInput!, $campaignId: String!) {
  updateClient(client: $client, campaignId: $campaignId)
}
    `;
export type UpdateClientMutationFn = Apollo.MutationFunction<UpdateClientMutation, UpdateClientMutationVariables>;

/**
 * __useUpdateClientMutation__
 *
 * To run a mutation, you first call `useUpdateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientMutation, { data, loading, error }] = useUpdateClientMutation({
 *   variables: {
 *      client: // value for 'client'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useUpdateClientMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientMutation, UpdateClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClientMutation, UpdateClientMutationVariables>(UpdateClientDocument, options);
      }
export type UpdateClientMutationHookResult = ReturnType<typeof useUpdateClientMutation>;
export type UpdateClientMutationResult = Apollo.MutationResult<UpdateClientMutation>;
export type UpdateClientMutationOptions = Apollo.BaseMutationOptions<UpdateClientMutation, UpdateClientMutationVariables>;
export const UpdateCampaignDeliverablesDocument = gql`
    mutation updateCampaignDeliverables($deliverables: UpdateDeliverablesInput!, $campaignId: String!) {
  updateCampaignDeliverables(deliverables: $deliverables, campaignId: $campaignId)
}
    `;
export type UpdateCampaignDeliverablesMutationFn = Apollo.MutationFunction<UpdateCampaignDeliverablesMutation, UpdateCampaignDeliverablesMutationVariables>;

/**
 * __useUpdateCampaignDeliverablesMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignDeliverablesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignDeliverablesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignDeliverablesMutation, { data, loading, error }] = useUpdateCampaignDeliverablesMutation({
 *   variables: {
 *      deliverables: // value for 'deliverables'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useUpdateCampaignDeliverablesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCampaignDeliverablesMutation, UpdateCampaignDeliverablesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCampaignDeliverablesMutation, UpdateCampaignDeliverablesMutationVariables>(UpdateCampaignDeliverablesDocument, options);
      }
export type UpdateCampaignDeliverablesMutationHookResult = ReturnType<typeof useUpdateCampaignDeliverablesMutation>;
export type UpdateCampaignDeliverablesMutationResult = Apollo.MutationResult<UpdateCampaignDeliverablesMutation>;
export type UpdateCampaignDeliverablesMutationOptions = Apollo.BaseMutationOptions<UpdateCampaignDeliverablesMutation, UpdateCampaignDeliverablesMutationVariables>;
export const DeleteCampaignDeliverableDocument = gql`
    mutation deleteCampaignDeliverable($deliverableId: Int!, $campaignId: String!) {
  deleteCampaignDeliverable(
    deliverableId: $deliverableId
    campaignId: $campaignId
  )
}
    `;
export type DeleteCampaignDeliverableMutationFn = Apollo.MutationFunction<DeleteCampaignDeliverableMutation, DeleteCampaignDeliverableMutationVariables>;

/**
 * __useDeleteCampaignDeliverableMutation__
 *
 * To run a mutation, you first call `useDeleteCampaignDeliverableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCampaignDeliverableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCampaignDeliverableMutation, { data, loading, error }] = useDeleteCampaignDeliverableMutation({
 *   variables: {
 *      deliverableId: // value for 'deliverableId'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useDeleteCampaignDeliverableMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCampaignDeliverableMutation, DeleteCampaignDeliverableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCampaignDeliverableMutation, DeleteCampaignDeliverableMutationVariables>(DeleteCampaignDeliverableDocument, options);
      }
export type DeleteCampaignDeliverableMutationHookResult = ReturnType<typeof useDeleteCampaignDeliverableMutation>;
export type DeleteCampaignDeliverableMutationResult = Apollo.MutationResult<DeleteCampaignDeliverableMutation>;
export type DeleteCampaignDeliverableMutationOptions = Apollo.BaseMutationOptions<DeleteCampaignDeliverableMutation, DeleteCampaignDeliverableMutationVariables>;
export const CreateInvoiceDocument = gql`
    mutation createInvoice($invoice: CreateInvoiceInput!, $campaignId: String!) {
  createInvoice(invoice: $invoice, campaignId: $campaignId)
}
    `;
export type CreateInvoiceMutationFn = Apollo.MutationFunction<CreateInvoiceMutation, CreateInvoiceMutationVariables>;

/**
 * __useCreateInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceMutation, { data, loading, error }] = useCreateInvoiceMutation({
 *   variables: {
 *      invoice: // value for 'invoice'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useCreateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInvoiceMutation, CreateInvoiceMutationVariables>(CreateInvoiceDocument, options);
      }
export type CreateInvoiceMutationHookResult = ReturnType<typeof useCreateInvoiceMutation>;
export type CreateInvoiceMutationResult = Apollo.MutationResult<CreateInvoiceMutation>;
export type CreateInvoiceMutationOptions = Apollo.BaseMutationOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>;
export const DeleteInvoiceDocument = gql`
    mutation deleteInvoice($invoiceId: String!, $campaignId: String!) {
  deleteInvoice(invoiceId: $invoiceId, campaignId: $campaignId)
}
    `;
export type DeleteInvoiceMutationFn = Apollo.MutationFunction<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>;

/**
 * __useDeleteInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvoiceMutation, { data, loading, error }] = useDeleteInvoiceMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useDeleteInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>(DeleteInvoiceDocument, options);
      }
export type DeleteInvoiceMutationHookResult = ReturnType<typeof useDeleteInvoiceMutation>;
export type DeleteInvoiceMutationResult = Apollo.MutationResult<DeleteInvoiceMutation>;
export type DeleteInvoiceMutationOptions = Apollo.BaseMutationOptions<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>;
export const UpdateInvoiceDocument = gql`
    mutation updateInvoice($invoice: UpdateInvoiceInput!, $campaignId: String!) {
  updateInvoice(invoice: $invoice, campaignId: $campaignId)
}
    `;
export type UpdateInvoiceMutationFn = Apollo.MutationFunction<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;

/**
 * __useUpdateInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceMutation, { data, loading, error }] = useUpdateInvoiceMutation({
 *   variables: {
 *      invoice: // value for 'invoice'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useUpdateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>(UpdateInvoiceDocument, options);
      }
export type UpdateInvoiceMutationHookResult = ReturnType<typeof useUpdateInvoiceMutation>;
export type UpdateInvoiceMutationResult = Apollo.MutationResult<UpdateInvoiceMutation>;
export type UpdateInvoiceMutationOptions = Apollo.BaseMutationOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;
export const RequestInvoiceFundingDocument = gql`
    mutation requestInvoiceFunding($invoiceId: String!, $campaignId: String!) {
  requestInvoiceFunding(invoiceId: $invoiceId, campaignId: $campaignId)
}
    `;
export type RequestInvoiceFundingMutationFn = Apollo.MutationFunction<RequestInvoiceFundingMutation, RequestInvoiceFundingMutationVariables>;

/**
 * __useRequestInvoiceFundingMutation__
 *
 * To run a mutation, you first call `useRequestInvoiceFundingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestInvoiceFundingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestInvoiceFundingMutation, { data, loading, error }] = useRequestInvoiceFundingMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useRequestInvoiceFundingMutation(baseOptions?: Apollo.MutationHookOptions<RequestInvoiceFundingMutation, RequestInvoiceFundingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestInvoiceFundingMutation, RequestInvoiceFundingMutationVariables>(RequestInvoiceFundingDocument, options);
      }
export type RequestInvoiceFundingMutationHookResult = ReturnType<typeof useRequestInvoiceFundingMutation>;
export type RequestInvoiceFundingMutationResult = Apollo.MutationResult<RequestInvoiceFundingMutation>;
export type RequestInvoiceFundingMutationOptions = Apollo.BaseMutationOptions<RequestInvoiceFundingMutation, RequestInvoiceFundingMutationVariables>;
export const UnderwriteInvoiceDocument = gql`
    mutation underwriteInvoice($invoice: UnderwriteInvoiceInput!, $campaignId: String!) {
  underwriteInvoice(invoice: $invoice, campaignId: $campaignId)
}
    `;
export type UnderwriteInvoiceMutationFn = Apollo.MutationFunction<UnderwriteInvoiceMutation, UnderwriteInvoiceMutationVariables>;

/**
 * __useUnderwriteInvoiceMutation__
 *
 * To run a mutation, you first call `useUnderwriteInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnderwriteInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [underwriteInvoiceMutation, { data, loading, error }] = useUnderwriteInvoiceMutation({
 *   variables: {
 *      invoice: // value for 'invoice'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useUnderwriteInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<UnderwriteInvoiceMutation, UnderwriteInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnderwriteInvoiceMutation, UnderwriteInvoiceMutationVariables>(UnderwriteInvoiceDocument, options);
      }
export type UnderwriteInvoiceMutationHookResult = ReturnType<typeof useUnderwriteInvoiceMutation>;
export type UnderwriteInvoiceMutationResult = Apollo.MutationResult<UnderwriteInvoiceMutation>;
export type UnderwriteInvoiceMutationOptions = Apollo.BaseMutationOptions<UnderwriteInvoiceMutation, UnderwriteInvoiceMutationVariables>;
export const SettleInvoiceDocument = gql`
    mutation settleInvoice($invoice: SettleInvoiceInput!, $campaignId: String!, $partnerId: String!) {
  settleInvoice(invoice: $invoice, campaignId: $campaignId, partnerId: $partnerId)
}
    `;
export type SettleInvoiceMutationFn = Apollo.MutationFunction<SettleInvoiceMutation, SettleInvoiceMutationVariables>;

/**
 * __useSettleInvoiceMutation__
 *
 * To run a mutation, you first call `useSettleInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettleInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settleInvoiceMutation, { data, loading, error }] = useSettleInvoiceMutation({
 *   variables: {
 *      invoice: // value for 'invoice'
 *      campaignId: // value for 'campaignId'
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useSettleInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<SettleInvoiceMutation, SettleInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SettleInvoiceMutation, SettleInvoiceMutationVariables>(SettleInvoiceDocument, options);
      }
export type SettleInvoiceMutationHookResult = ReturnType<typeof useSettleInvoiceMutation>;
export type SettleInvoiceMutationResult = Apollo.MutationResult<SettleInvoiceMutation>;
export type SettleInvoiceMutationOptions = Apollo.BaseMutationOptions<SettleInvoiceMutation, SettleInvoiceMutationVariables>;
export const CreateClientSignOffRequestDocument = gql`
    mutation createClientSignOffRequest($deliverables: [Int!]!, $campaignId: String!, $signOffId: Int!, $clientSignOffDocument: String) {
  createClientSignOffRequest(
    deliverables: $deliverables
    campaignId: $campaignId
    signOffId: $signOffId
    clientSignOffDocument: $clientSignOffDocument
  )
}
    `;
export type CreateClientSignOffRequestMutationFn = Apollo.MutationFunction<CreateClientSignOffRequestMutation, CreateClientSignOffRequestMutationVariables>;

/**
 * __useCreateClientSignOffRequestMutation__
 *
 * To run a mutation, you first call `useCreateClientSignOffRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientSignOffRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientSignOffRequestMutation, { data, loading, error }] = useCreateClientSignOffRequestMutation({
 *   variables: {
 *      deliverables: // value for 'deliverables'
 *      campaignId: // value for 'campaignId'
 *      signOffId: // value for 'signOffId'
 *      clientSignOffDocument: // value for 'clientSignOffDocument'
 *   },
 * });
 */
export function useCreateClientSignOffRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateClientSignOffRequestMutation, CreateClientSignOffRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClientSignOffRequestMutation, CreateClientSignOffRequestMutationVariables>(CreateClientSignOffRequestDocument, options);
      }
export type CreateClientSignOffRequestMutationHookResult = ReturnType<typeof useCreateClientSignOffRequestMutation>;
export type CreateClientSignOffRequestMutationResult = Apollo.MutationResult<CreateClientSignOffRequestMutation>;
export type CreateClientSignOffRequestMutationOptions = Apollo.BaseMutationOptions<CreateClientSignOffRequestMutation, CreateClientSignOffRequestMutationVariables>;
export const DeleteClientSignOffDocument = gql`
    mutation deleteClientSignOff($clientSignOffId: Int!, $campaignId: String!) {
  deleteClientSignOff(clientSignOffId: $clientSignOffId, campaignId: $campaignId)
}
    `;
export type DeleteClientSignOffMutationFn = Apollo.MutationFunction<DeleteClientSignOffMutation, DeleteClientSignOffMutationVariables>;

/**
 * __useDeleteClientSignOffMutation__
 *
 * To run a mutation, you first call `useDeleteClientSignOffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientSignOffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientSignOffMutation, { data, loading, error }] = useDeleteClientSignOffMutation({
 *   variables: {
 *      clientSignOffId: // value for 'clientSignOffId'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useDeleteClientSignOffMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClientSignOffMutation, DeleteClientSignOffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClientSignOffMutation, DeleteClientSignOffMutationVariables>(DeleteClientSignOffDocument, options);
      }
export type DeleteClientSignOffMutationHookResult = ReturnType<typeof useDeleteClientSignOffMutation>;
export type DeleteClientSignOffMutationResult = Apollo.MutationResult<DeleteClientSignOffMutation>;
export type DeleteClientSignOffMutationOptions = Apollo.BaseMutationOptions<DeleteClientSignOffMutation, DeleteClientSignOffMutationVariables>;
export const CompleteCampaignDocument = gql`
    mutation completeCampaign($campaignId: String!, $partnerId: String!) {
  completeCampaign(campaignId: $campaignId, partnerId: $partnerId)
}
    `;
export type CompleteCampaignMutationFn = Apollo.MutationFunction<CompleteCampaignMutation, CompleteCampaignMutationVariables>;

/**
 * __useCompleteCampaignMutation__
 *
 * To run a mutation, you first call `useCompleteCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeCampaignMutation, { data, loading, error }] = useCompleteCampaignMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useCompleteCampaignMutation(baseOptions?: Apollo.MutationHookOptions<CompleteCampaignMutation, CompleteCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteCampaignMutation, CompleteCampaignMutationVariables>(CompleteCampaignDocument, options);
      }
export type CompleteCampaignMutationHookResult = ReturnType<typeof useCompleteCampaignMutation>;
export type CompleteCampaignMutationResult = Apollo.MutationResult<CompleteCampaignMutation>;
export type CompleteCampaignMutationOptions = Apollo.BaseMutationOptions<CompleteCampaignMutation, CompleteCampaignMutationVariables>;
export const ResendClientSignOffRequestDocument = gql`
    mutation resendClientSignOffRequest($campaignId: String!, $clientSignOffId: Int!) {
  resendClientSignOffRequest(
    campaignId: $campaignId
    clientSignOffId: $clientSignOffId
  )
}
    `;
export type ResendClientSignOffRequestMutationFn = Apollo.MutationFunction<ResendClientSignOffRequestMutation, ResendClientSignOffRequestMutationVariables>;

/**
 * __useResendClientSignOffRequestMutation__
 *
 * To run a mutation, you first call `useResendClientSignOffRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendClientSignOffRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendClientSignOffRequestMutation, { data, loading, error }] = useResendClientSignOffRequestMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      clientSignOffId: // value for 'clientSignOffId'
 *   },
 * });
 */
export function useResendClientSignOffRequestMutation(baseOptions?: Apollo.MutationHookOptions<ResendClientSignOffRequestMutation, ResendClientSignOffRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendClientSignOffRequestMutation, ResendClientSignOffRequestMutationVariables>(ResendClientSignOffRequestDocument, options);
      }
export type ResendClientSignOffRequestMutationHookResult = ReturnType<typeof useResendClientSignOffRequestMutation>;
export type ResendClientSignOffRequestMutationResult = Apollo.MutationResult<ResendClientSignOffRequestMutation>;
export type ResendClientSignOffRequestMutationOptions = Apollo.BaseMutationOptions<ResendClientSignOffRequestMutation, ResendClientSignOffRequestMutationVariables>;
import { PartnerAddressSchema } from "shared/utils/validators";
import { SchemaOf, array, mixed, object, string } from "yup";

export type OrganisationProfileFormObject = {
  logo: any;
  functionalCurrencies: string[];
  address: {
    addressLine1: string;
    addressLine2?: string;
    city: string;
    postcode: string;
  };
};

const MAX_IMAGE_FILE_SIZE = 5000000; //5MB

const validImageExtensions: string[] = ["png", "jpg", "jpeg"];

function isValidFileType(value: FileList) {
  if (value && value.length > 0) {
    return validImageExtensions.includes(value[0].name.split(".").pop()!);
  } else return false;
}

export const organisationProfileSchema = <SchemaOf<OrganisationProfileFormObject>>object().shape({
  logo: mixed()
    .required("Please select a file to upload")
    .test("is-required", "Please select a file to upload", (value) => {
      if (value && value.length === 0) {
        return false;
      }
      return true;
    })
    .test("is-valid-type", `Invalid file. Only the following types are allowed: ${validImageExtensions.join(", ")}`, (value) => isValidFileType(value))
    .test("is-valid-size", "File to large. Maximum file size is 5MB", (value) => value && value.length > 0 && value[0].size <= MAX_IMAGE_FILE_SIZE),
  address: PartnerAddressSchema,
  functionalCurrencies: array()
    .of(string().required("Please select at least 1 currency"))
    .test("min-test", "Please select at least one currency", (value) => {
      if (!value || value.length <= 0) return false;
      return true;
    }),
});

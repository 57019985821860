import TailwindDialog from "components/tailwind/headlessTailwind/TailwindDialog";
import { Campaign } from "shared/types/Campaign";
import { toast } from "sonner";
import { getFileName } from "utils/file";
import { useRemoveCampaignDocumentMutation } from "views/campaigns/graphql/mutations.generated";

type DeleteDocumentModalProps = {
  refetchCampaign(): void;
  file: string;
  campaign?: Campaign;
};

const DeleteDocumentModal: React.FC<DeleteDocumentModalProps> = ({ campaign, refetchCampaign, file }) => {
  const [removeCampaignDocument] = useRemoveCampaignDocumentMutation();

  const handleDocumentDelete = (): Promise<boolean> => {
    return new Promise((resolve) => {
      if (campaign && file) {
        removeCampaignDocument({
          variables: {
            file: file,
            campaignId: campaign?.campaignId,
          },
        })
          .then(() => {
            toast.success("Success", { description: "Document has been deleted" });
            refetchCampaign();
          })
          .catch((err) => {
            toast.error("Request failed", { description: err.message });
          })
          .finally(() => {
            resolve(true);
          });
      }
    });
  };

  return (
    <TailwindDialog type="danger" title="Delete document" submitButtonColor="red-solid" onConfirmCallback={handleDocumentDelete}>
      Are you sure? You will be permanently deleting document <span className="font-semibold">{getFileName(file)}</span>. This action can not be undone
    </TailwindDialog>
  );
};

export default DeleteDocumentModal;

import { yupResolver } from "@hookform/resolvers/yup";
import { ReactComponent as MinusIcon } from "assets/tailwind/icons/minus-circle.svg";
import { ReactComponent as PlusIcon } from "assets/tailwind/icons/plus.svg";
import MonetButton from "components/tailwind/MonetButton";
import MonetDrawerButtons from "components/tailwind/MonetDrawerButtons";
import MonetToolTip from "components/tailwind/MonetTooltip";
import MonetInput from "components/tailwind/form/MonetInput";
import MonetTextArea from "components/tailwind/form/MonetTextArea";
import TailwindDrawer from "components/tailwind/headlessTailwind/TailwindDrawer";
import { useModal } from "contexts/ModalProvider";
import { Resolver, useFieldArray, useForm } from "react-hook-form";
import { CampaignDeliverable } from "shared/types/Campaign";
import { AddDeliverablesFormObject, updateDeliverablesSchema } from "shared/utils/validators";
import { toast } from "sonner";
import { useUpdateCampaignDeliverablesMutation } from "views/campaigns/graphql/mutations.generated";

type UpdateDeliverablesDrawerProps = {
  refetchCampaign(): void;
  campaignId?: string;
  deliverable?: CampaignDeliverable;
};

const UpdateDeliverablesDrawer: React.FC<UpdateDeliverablesDrawerProps> = ({ refetchCampaign, campaignId, deliverable }) => {
  const [updateDeliverables] = useUpdateCampaignDeliverablesMutation();
  const { closeModal } = useModal();

  const onSubmit = async (values: AddDeliverablesFormObject) => {
    if (campaignId) {
      try {
        await updateDeliverables({
          variables: {
            deliverables: {
              ...values,
              id: deliverable?.deliverableId ?? undefined,
            },
            campaignId: campaignId,
          },
        });
        toast.success("Success", { description: "The campaigns deliverables have been updated" });
        refetchCampaign();
        closeModal();
      } catch (error: any) {
        toast.error("Request failed", { description: error.message });
      }
    }
  };

  const {
    formState: { isSubmitting, isValid, errors },
    register,
    handleSubmit,
    control,
  } = useForm<AddDeliverablesFormObject>({
    mode: "onTouched",
    resolver: yupResolver(updateDeliverablesSchema) as Resolver<AddDeliverablesFormObject>,
    values: {
      name: deliverable?.name ?? "",
      description: deliverable?.description ?? "",
      liveLinks: deliverable?.liveLinks ?? [],
    },
  });

  const { fields, append, remove } = useFieldArray<any>({
    control: control,
    name: "liveLinks",
  });

  return (
    <>
      <TailwindDrawer title="Campaign deliverable" subtitle="Deliverables are the tasks that must be completed as part of this campaign">
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col flex-1 justify-between mt-2 gap-6">
          <div className="flex flex-col gap-2">
            <MonetInput {...register("name")} label="Name" error={errors.name?.message as string} disabled={isSubmitting} id="deliverableName" type="text" />
            <MonetTextArea
              {...register("description")}
              label="Description"
              error={errors.description?.message as string}
              disabled={isSubmitting}
              isOptional={true}
              tooltip="An optional short summary that describes the deliverables"
              id="description"
            />
            <ul className="flex flex-col sm:gap-1 mb-5">
              <div className="flex flex-row text-sm">
                <p className="block font-semibold text-gray-800 dark:text-white text-sm mb-2">
                  Links to delivered content<sup>(optional)</sup>
                </p>
                <MonetToolTip text="Links to the live content that has been published as part of this deliverable" />
              </div>
              {fields.map((field, index) => (
                <li key={field.id} className="relative">
                  {index > 0 && (
                    <MonetButton
                      variant="solid"
                      size="fit-content"
                      className="bg-red-600 hover:bg-red-700 text-white rounded-full absolute top-[-5px] left-[-5px] z-10"
                      onClick={() => {
                        remove(index);
                      }}
                      disabled={isSubmitting}
                    >
                      <MinusIcon width={14} height={14} />
                    </MonetButton>
                  )}
                  <MonetInput {...register(`liveLinks.${index}`)} disabled={isSubmitting} error={errors.liveLinks?.[index]?.message as string} id={`liveLinks.${index}`} />
                </li>
              ))}
              <MonetButton
                size="x-small"
                variant="outlined"
                className="w-full text-center"
                onClick={() => {
                  append("");
                }}
                disabled={isSubmitting}
              >
                <PlusIcon /> Add link
              </MonetButton>
            </ul>
          </div>
          <MonetDrawerButtons cancelDisabled={isSubmitting} submitDisabled={isSubmitting || !isValid} loading={isSubmitting} />
        </form>
      </TailwindDrawer>
    </>
  );
};

export default UpdateDeliverablesDrawer;

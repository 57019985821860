import MonetDrawerButtons from "components/tailwind/MonetDrawerButtons";
import React from "react";

type CreateSignOffSelectionProps = {
  onSelectionCallback: React.Dispatch<React.SetStateAction<number | undefined>>;
};

const CreateSignOffSelection: React.FC<CreateSignOffSelectionProps> = ({ onSelectionCallback }) => {
  const handleSelection = (selection: number) => {
    onSelectionCallback(selection);
  };

  return (
    <div className="flex flex-col h-full w-full">
      <div className="h-full w-full flex flex-col justify-center">
        <div className="flex flex-col justify-center border border-gray-200 rounded-lg p-4">
          <button onClick={() => handleSelection(1)}>
            <div className="flex justify-center text-sm text-center p-4 rounded-lg hover:bg-blue-50 hover:cursor-pointer">
              <div className="max-w-[325px]">
                <p className="font-semibold">
                  Client sign off <span className="font-normal">{`(preferred)`}</span>
                </p>
                <p>Request a sign off using MONET’s client sign off journey</p>
              </div>
            </div>
          </button>
          <div className="my-8">
            <div className="relative flex justify-center items-center top-[50%]">
              <p className="absolute bg-white px-2 text-xs">or</p>
            </div>
            <div className="h-[1px] bg-gray-200 max-w-[250px] mx-auto"></div>
          </div>
          <button onClick={() => handleSelection(2)}>
            <div className="flex justify-center text-sm text-center p-4 rounded-lg hover:bg-blue-50 hover:cursor-pointer">
              <div className="max-w-[325px]">
                <p className="font-semibold">Manual sign off</p>
                <p>Where using MONET’s client sign off is not suitable you can upload proof of client sign off</p>
              </div>
            </div>
          </button>
        </div>
      </div>
      <MonetDrawerButtons isPassive={true} cancelDisabled={false} submitDisabled={false} />
    </div>
  );
};

export default CreateSignOffSelection;

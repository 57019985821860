import TailwindDialog from "components/tailwind/headlessTailwind/TailwindDialog";
import React, { useCallback } from "react";
import { Campaign } from "shared/types/Campaign";
import { toast } from "sonner";
import { useCompleteCampaignMutation } from "views/campaigns/graphql/mutations.generated";

type CompleteCampaignModalProps = {
  campaign?: Campaign;
  refetchCampaign(): void;
};

const CompleteCampaignModal: React.FC<CompleteCampaignModalProps> = ({ campaign, refetchCampaign }) => {
  const [completeCampaign] = useCompleteCampaignMutation();

  const handleComplete = useCallback((): Promise<boolean> => {
    return new Promise((resolve) => {
      if (campaign) {
        completeCampaign({
          variables: {
            campaignId: campaign.campaignId,
            partnerId: campaign.partnerId,
          },
        })
          .then(() => {
            toast.success("Success", { description: "Campaign has been marked as complete" });
            refetchCampaign();
          })
          .catch((err: any) => {
            toast.error("Request failed", { description: err.message });
          })
          .finally(() => {
            resolve(true);
          });
      }
    });
  }, [campaign]);

  return (
    <TailwindDialog type="info" title="Complete campaign" onConfirmCallback={() => handleComplete()}>
      Are you sure you want to mark the campaign <span className="font-semibold">{campaign && campaign.campaignName}</span> as complete? Once completed, you won't be able to funding for this campaign.
    </TailwindDialog>
  );
};

export default CompleteCampaignModal;

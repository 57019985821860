import * as Types from 'shared/generated/ep3.graphql.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StripeFulfillCheckoutMutationVariables = Types.Exact<{
  stripeSessionId: Types.Scalars['String']['input'];
}>;


export type StripeFulfillCheckoutMutation = { __typename?: 'Mutation', stripeFulfillCheckout: boolean };

export type CompleteOrgProfileMutationVariables = Types.Exact<{
  logo: Types.LogoInput;
  address: Types.Address;
  functionalCurrencies: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;


export type CompleteOrgProfileMutation = { __typename?: 'Mutation', completeOrgProfile: boolean };

export type CreateCodatCompanyMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type CreateCodatCompanyMutation = { __typename?: 'Mutation', createCodatCompany: any };

export type CompleteOnboardingMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type CompleteOnboardingMutation = { __typename?: 'Mutation', completeOnboarding: boolean };


export const StripeFulfillCheckoutDocument = gql`
    mutation stripeFulfillCheckout($stripeSessionId: String!) {
  stripeFulfillCheckout(stripeSessionId: $stripeSessionId)
}
    `;
export type StripeFulfillCheckoutMutationFn = Apollo.MutationFunction<StripeFulfillCheckoutMutation, StripeFulfillCheckoutMutationVariables>;

/**
 * __useStripeFulfillCheckoutMutation__
 *
 * To run a mutation, you first call `useStripeFulfillCheckoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripeFulfillCheckoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripeFulfillCheckoutMutation, { data, loading, error }] = useStripeFulfillCheckoutMutation({
 *   variables: {
 *      stripeSessionId: // value for 'stripeSessionId'
 *   },
 * });
 */
export function useStripeFulfillCheckoutMutation(baseOptions?: Apollo.MutationHookOptions<StripeFulfillCheckoutMutation, StripeFulfillCheckoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StripeFulfillCheckoutMutation, StripeFulfillCheckoutMutationVariables>(StripeFulfillCheckoutDocument, options);
      }
export type StripeFulfillCheckoutMutationHookResult = ReturnType<typeof useStripeFulfillCheckoutMutation>;
export type StripeFulfillCheckoutMutationResult = Apollo.MutationResult<StripeFulfillCheckoutMutation>;
export type StripeFulfillCheckoutMutationOptions = Apollo.BaseMutationOptions<StripeFulfillCheckoutMutation, StripeFulfillCheckoutMutationVariables>;
export const CompleteOrgProfileDocument = gql`
    mutation completeOrgProfile($logo: LogoInput!, $address: Address!, $functionalCurrencies: [String!]!) {
  completeOrgProfile(
    logo: $logo
    address: $address
    functionalCurrencies: $functionalCurrencies
  )
}
    `;
export type CompleteOrgProfileMutationFn = Apollo.MutationFunction<CompleteOrgProfileMutation, CompleteOrgProfileMutationVariables>;

/**
 * __useCompleteOrgProfileMutation__
 *
 * To run a mutation, you first call `useCompleteOrgProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteOrgProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeOrgProfileMutation, { data, loading, error }] = useCompleteOrgProfileMutation({
 *   variables: {
 *      logo: // value for 'logo'
 *      address: // value for 'address'
 *      functionalCurrencies: // value for 'functionalCurrencies'
 *   },
 * });
 */
export function useCompleteOrgProfileMutation(baseOptions?: Apollo.MutationHookOptions<CompleteOrgProfileMutation, CompleteOrgProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteOrgProfileMutation, CompleteOrgProfileMutationVariables>(CompleteOrgProfileDocument, options);
      }
export type CompleteOrgProfileMutationHookResult = ReturnType<typeof useCompleteOrgProfileMutation>;
export type CompleteOrgProfileMutationResult = Apollo.MutationResult<CompleteOrgProfileMutation>;
export type CompleteOrgProfileMutationOptions = Apollo.BaseMutationOptions<CompleteOrgProfileMutation, CompleteOrgProfileMutationVariables>;
export const CreateCodatCompanyDocument = gql`
    mutation createCodatCompany {
  createCodatCompany
}
    `;
export type CreateCodatCompanyMutationFn = Apollo.MutationFunction<CreateCodatCompanyMutation, CreateCodatCompanyMutationVariables>;

/**
 * __useCreateCodatCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCodatCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCodatCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCodatCompanyMutation, { data, loading, error }] = useCreateCodatCompanyMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateCodatCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCodatCompanyMutation, CreateCodatCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCodatCompanyMutation, CreateCodatCompanyMutationVariables>(CreateCodatCompanyDocument, options);
      }
export type CreateCodatCompanyMutationHookResult = ReturnType<typeof useCreateCodatCompanyMutation>;
export type CreateCodatCompanyMutationResult = Apollo.MutationResult<CreateCodatCompanyMutation>;
export type CreateCodatCompanyMutationOptions = Apollo.BaseMutationOptions<CreateCodatCompanyMutation, CreateCodatCompanyMutationVariables>;
export const CompleteOnboardingDocument = gql`
    mutation completeOnboarding {
  completeOnboarding
}
    `;
export type CompleteOnboardingMutationFn = Apollo.MutationFunction<CompleteOnboardingMutation, CompleteOnboardingMutationVariables>;

/**
 * __useCompleteOnboardingMutation__
 *
 * To run a mutation, you first call `useCompleteOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeOnboardingMutation, { data, loading, error }] = useCompleteOnboardingMutation({
 *   variables: {
 *   },
 * });
 */
export function useCompleteOnboardingMutation(baseOptions?: Apollo.MutationHookOptions<CompleteOnboardingMutation, CompleteOnboardingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteOnboardingMutation, CompleteOnboardingMutationVariables>(CompleteOnboardingDocument, options);
      }
export type CompleteOnboardingMutationHookResult = ReturnType<typeof useCompleteOnboardingMutation>;
export type CompleteOnboardingMutationResult = Apollo.MutationResult<CompleteOnboardingMutation>;
export type CompleteOnboardingMutationOptions = Apollo.BaseMutationOptions<CompleteOnboardingMutation, CompleteOnboardingMutationVariables>;
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import MonetButton from "components/tailwind/MonetButton";
import { useState } from "react";

type FeedbackModalProps = {
  feedback?: string;
};

const FeedbackModal: React.FC<FeedbackModalProps> = ({ feedback }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  if (!feedback) {
    return <p className="text-sm">-</p>;
  }

  return (
    <>
      <MonetButton variant="link" size="fit-content" className="font-normal" onClick={toggleModal}>
        View
      </MonetButton>
      <Dialog transition open={isModalOpen} onClose={toggleModal} className="relative z-50">
        <DialogBackdrop className="fixed inset-0 bg-black/30" />
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <DialogPanel
            transition
            className="flex flex-col bg-white border shadow-sm max-h-[500px] overflow-auto max-w-[600px] p-4 w-full rounded-lg pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70 transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="flex flex-col items-center gap-4">
              <div className="flex flex-col gap-4 w-full text-sm">{feedback}</div>
              <div className="flex flex-row gap-4 w-full justify-end">
                <MonetButton variant="outlined" color="gray-outlined" className="w-fit" size="small" onClick={toggleModal}>
                  Close
                </MonetButton>
              </div>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
};

export default FeedbackModal;

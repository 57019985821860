import { CodatLinkProps, initializeCodatLink } from "@codat/sdk-link-types";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { useCreateCodatCompanyMutation } from "components/onboardingStepper/graphql/mutations.generated";
import MonetButton from "components/tailwind/MonetButton";
import MonetCard from "components/tailwind/MonetCard";
import MonetCardTitle from "components/tailwind/MonetCardTitle";
import { useSessionProvider } from "contexts/SessionProvider";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "sonner";
import { useUpdateCodatMutation } from "views/organisation/graphql/mutations.generated";

const Integrations: React.FC = () => {
  const [componentMount, setComponentMount] = useState<HTMLDivElement | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [companyId, setCompanyId] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const [createCodatCompany] = useCreateCodatCompanyMutation();
  const [updateCodat] = useUpdateCodatMutation();

  const { partner, refetchCurrentPartner } = useSessionProvider();

  useEffect(() => {
    const target = componentMount;

    if (companyId) {
      const props: CodatLinkProps = {
        companyId: companyId,
        onConnection: () => console.debug("Codat connection successful"),
        onError: (err) => toast.error(err.message),
        onClose: () => setIsModalOpen(false),
        onFinish: async () => {
          setIsModalOpen(false);
          setIsLoading(true);
          await updateCodat()
            .then(async () => {
              await refetchCurrentPartner();
              toast.success("Integrations updated");
            })
            .catch((error) => {
              toast.error(error.message);
            })
            .finally(() => {
              setIsLoading(false);
            });
        },
      };

      if (target && target.children.length === 0) {
        initializeCodatLink(target, props);
      }
    }
  }, [componentMount, companyId]);

  const handleClick = useCallback(async () => {
    if (partner) {
      if (partner.integrations?.codat.companyId) {
        setCompanyId(partner.integrations?.codat.companyId);
        setIsModalOpen(true);
      } else {
        setIsLoading(true);
        await createCodatCompany()
          .then((response) => {
            if (response.data) {
              setCompanyId(JSON.parse(response.data.createCodatCompany).id);
              setIsModalOpen(true);
            }
          })
          .catch((error) => {
            toast.error(error.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }, [partner]);

  return (
    <>
      <MonetCard>
        <MonetCardTitle>Integrations</MonetCardTitle>
        <p className="text-sm">Review and manage your integrations. To access campaign financing, please ensure your accounting software is synced.</p>
        <div>
          <MonetButton onClick={handleClick} loading={isLoading} disabled={isLoading}>
            Manage integrations
          </MonetButton>
        </div>
      </MonetCard>
      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} className="relative z-50">
        <DialogBackdrop className="fixed inset-0 bg-black/30" />
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <DialogPanel className="h-full flex flex-col bg-white border shadow-sm overflow-auto rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
            <div className="w-[460px] h-[840px]" ref={setComponentMount}></div>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
};

export default Integrations;

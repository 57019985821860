import MonetButton from "components/tailwind/MonetButton";
import MonetCard from "components/tailwind/MonetCard";
import MonetCardTitle from "components/tailwind/MonetCardTitle";
import MonetLoadingSpinner from "components/tailwind/MonetLoadingSpinner";
import NoDataCard from "components/tailwind/NoDataCard";
import { useModal } from "contexts/ModalProvider";
import { useSessionProvider } from "contexts/SessionProvider";
import { useMemo } from "react";
import { Campaign } from "shared/types/Campaign";
import { isInPartnerContext } from "utils/User";
import ClientSignOffTable from "./ClientSignOffTable";
import RequestClientSignOffDrawer from "./createSignOff/CreateClientSignOffDrawer";

type ClientSignOffProps = {
  campaign?: Campaign;
  isLoading: boolean;
  refetchCampaign(): void;
};

const ClientSignOff: React.FC<ClientSignOffProps> = ({ campaign, refetchCampaign, isLoading }) => {
  const { openModal } = useModal();
  const { user } = useSessionProvider();

  const filteredDeliverables = useMemo(() => {
    if (!campaign || !campaign.deliverables) {
      return [];
    }

    return campaign.deliverables.filter((deliverable) => deliverable.status !== "SIGNED_OFF");
  }, [campaign]);

  return (
    <MonetCard>
      <div>
        <div className="flex flex-row justify-between">
          <MonetCardTitle>Client sign off</MonetCardTitle>
          <div className="flex flex-row gap-4 min-w-fit">
            {campaign?.campaignStatus !== "COMPLETED" && isInPartnerContext(user) && (campaign?.clientSignOff?.length ?? 0) > 0 && (
              <MonetButton size="x-small" disabled={isLoading} onClick={() => openModal(RequestClientSignOffDrawer, { campaign: campaign, refetchCampaign: refetchCampaign })}>
                Request sign off
              </MonetButton>
            )}
          </div>
        </div>
        <p className="text-sm sm:max-w-[80%] lg:max-w-[60%]">
          Request client approval for completed work. Your client will automatically receive an email to review and approve the deliverables. You can also copy the link below to
          send it directly.
        </p>
      </div>
      <div>
        {isLoading ? (
          <MonetLoadingSpinner size="default" />
        ) : campaign?.clientSignOff?.length ? (
          <ClientSignOffTable refetchCampaign={refetchCampaign} campaign={campaign} />
        ) : (
          <NoDataCard showAsUnstyledCard={true}>
            No sign off requests
            {campaign && isInPartnerContext(user) && filteredDeliverables.length > 0 && (
              <MonetButton
                size="x-small"
                variant="link"
                className="w-fit underline"
                onClick={() => openModal(RequestClientSignOffDrawer, { campaign: campaign, refetchCampaign: refetchCampaign })}
              >
                Request sign off
              </MonetButton>
            )}
          </NoDataCard>
        )}
      </div>
    </MonetCard>
  );
};

export default ClientSignOff;

import { useModal } from "contexts/ModalProvider";
import MonetButton from "./MonetButton";
import { useCallback } from "react";

type MonetDrawerButtonsProps = {
  drawerId?: string;
  cancelDisabled: boolean;
  submitDisabled: boolean;
  loading?: boolean;
  isPassive?: boolean;
  cancelBtnText?: string;
  onCancel?: () => void;
};

const MonetDrawerButtons: React.FC<MonetDrawerButtonsProps> = ({ submitDisabled, cancelDisabled, loading, isPassive, cancelBtnText, onCancel }) => {
  const { closeModal } = useModal();

  const handleCancel = useCallback(() => {
    if(onCancel) {
      onCancel();
    } else {
      closeModal();
    }
  }, [onCancel]);

  return (
    <div className="flex flex-row gap-4 mt-4">
      <MonetButton onClick={handleCancel} variant="outlined" color="gray-outlined" className="flex-1" disabled={cancelDisabled}>
        {cancelBtnText ? cancelBtnText : (isPassive ? "Close" : "Cancel")}
      </MonetButton>
      {!isPassive && (
        <MonetButton type="submit" className="flex-1" loading={loading} disabled={submitDisabled}>
          Confirm
        </MonetButton>
      )}
    </div>
  );
};

export default MonetDrawerButtons;

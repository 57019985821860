import { MonetBadgeVariants } from "components/tailwind/MonetBadge";
import { Campaign, ClientSignOff } from "shared/types/Campaign";

export const getCampaignField = <T extends keyof Campaign>(campaignAttribute: T, campaignId: string, campaigns: Campaign[]): string | undefined => {
  const campaign = campaigns.find((campaign) => campaign.campaignId === campaignId);
  if (campaign) {
    return campaign[campaignAttribute] as string;
  }

  return undefined;
};

export const getClientSignOffStatusSettings = (status: ClientSignOff["status"]): { color: MonetBadgeVariants["variant"] } => {
  switch (status) {
    case "PENDING":
      return {
        color: "blue",
      };
    case "APPROVED":
      return {
        color: "green",
      };
    case "REJECTED":
      return {
        color: "red",
      };
    default:
      return {
        color: "gray",
      };
  }
};

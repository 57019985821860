import React, { useEffect } from "react";

interface MonetBasicStepperProps {
  steps: any[];
  currentStep: number;
}

const MonetBasicStepper: React.FC<MonetBasicStepperProps> = ({ steps, currentStep }) => {
  useEffect(() => {
    console.log(currentStep);
    console.log(steps);
    steps.map((step, index) => {
      console.log(index);
      console.log(currentStep);
      console.log(currentStep === index + 1);
    });
  }, [currentStep]);
  return (
    <ol className="flex items-center w-full justify-center text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base p-2 sm:p-5">
      {steps.map((step, index) =>
        index === steps.length - 1 ? (
          <li className={`${currentStep === index + 1 && "text-monet-blue"} ${step.completed && "text-teal-500"} flex items-center`}>
            <span className="me-2">{index + 1}</span>
            <span className="hidden sm:inline-block">{step.label}</span>
          </li>
        ) : (
          <li
            className={`flex md:w-full items-center ${currentStep === index + 1 && "text-monet-blue"} ${step.completed && "text-teal-500"} sm:after:content-[''] "after:flex-1 after:w-full after:border-b after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700"`}
            key={index}
          >
            <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2">
              {step.completed ? (
                <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
              ) : (
                <span className="me-2">{index + 1}</span>
              )}
              <span className="hidden sm:inline-block">{step.label}</span>
            </span>
          </li>
        ),
      )}
    </ol>
  );
};

export default MonetBasicStepper;

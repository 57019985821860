import { useMemo } from "react";
import { Campaign, ClientSignOff, DeliverableStatus } from "shared/types/Campaign";
import { Invoice, InvoiceFundingStatus } from "shared/types/Invoice";
import { Partner, PartnerSubscriptionStatus } from "shared/types/Partner";
import { Payout } from "shared/types/Payout";
import { getClientSignOffStatusSettings } from "utils/Campaign";
import { getInvoiceStatusSettings } from "utils/Invoice";
import { getPartnerKYBStatusSettings, getPartnerSubscriptionSettings } from "utils/Partner";
import { getPayoutStatusSettings } from "utils/Payouts";
import { enumToNiceString } from "utils/strings";
import { getCampaignDeliverableStatusSettings, getCampaignStatusColor } from "views/campaigns/utils";
import MonetBadge from "./MonetBadge";

type StatusBadgeProps = {
  type: "campaign" | "payout" | "partner" | "deliverable" | "invoice" | "subscription" | "clientSignOff";
  text?: string;
};

const StatusBadge: React.FC<StatusBadgeProps> = ({ type, text }) => {
  const config = useMemo(() => {
    if (type === "campaign") {
      return getCampaignStatusColor(text as Campaign["campaignStatus"]);
    } else if (type === "payout") {
      return getPayoutStatusSettings(text as Payout["payoutStatus"]);
    } else if (type === "partner") {
      return getPartnerKYBStatusSettings(text as Partner["kybStatus"]);
    } else if (type === "deliverable") {
      return getCampaignDeliverableStatusSettings(text as DeliverableStatus);
    } else if (type === "invoice") {
      return getInvoiceStatusSettings(text as Invoice["invoiceStatus"] | InvoiceFundingStatus);
    } else if (type === "subscription") {
      return getPartnerSubscriptionSettings(text as PartnerSubscriptionStatus);
    } else if (type === "clientSignOff") {
      return getClientSignOffStatusSettings(text as ClientSignOff["status"]);
    } else return null;
  }, [type, text]);

  if (!text) return null;

  return <MonetBadge variant={config?.color}>{enumToNiceString(text)}</MonetBadge>;
};

export default StatusBadge;

import { yupResolver } from "@hookform/resolvers/yup";
import MonetBorderedCardContent from "components/tailwind/MonetBorderedCardContent";
import MonetDrawerButtons from "components/tailwind/MonetDrawerButtons";
import MonetFileInput from "components/tailwind/form/MonetFileInput";
import MonetSwitch from "components/tailwind/form/MonetSwitch";
import { useModal } from "contexts/ModalProvider";
import { useSessionProvider } from "contexts/SessionProvider";
import { useEffect, useMemo, useState } from "react";
import { Resolver, useForm } from "react-hook-form";
import { Campaign } from "shared/types/Campaign";
import { toast } from "sonner";
import { uploadFiles } from "utils/file";
import { useCreateClientSignOffRequestMutation } from "views/campaigns/graphql/mutations.generated";
import { uploadCampaignFiles } from "views/campaigns/utils";
import { ClientSignOffFormObject, clientSignOffSchema } from "views/campaigns/validationSchema/CreateClientSignOffSchema";

type ManualSignOffViewProps = {
  refetchCampaign(): void;
  campaign?: Campaign;
  onBackCallback: React.Dispatch<React.SetStateAction<number | undefined>>;
};

const ManualSignOffView: React.FC<ManualSignOffViewProps> = ({ refetchCampaign, campaign, onBackCallback }) => {
  const [createClientSignOffRequest] = useCreateClientSignOffRequestMutation();
  const [selectedDeliverables, setSelectedDeliverables] = useState<number[] | undefined>();
  const { closeModal } = useModal();
  const { user } = useSessionProvider();

  const onSubmit = async (values: ClientSignOffFormObject) => {
    if (campaign) {
      try {
        const signOffId = Math.floor(Date.now() / 1000);
        const res = await uploadFiles(values.signOffDocument!, user, `clientSignOff/${signOffId}`);
        if (res.length > 0) {
          await createClientSignOffRequest({
            variables: {
              deliverables: values.deliverables,
              campaignId: campaign.campaignId,
              signOffId: signOffId,
              clientSignOffDocument: res[0],
            },
          }).then(() => {
            refetchCampaign();
            closeModal();
            toast.success("Sign off uploaded");
          });
        } else throw new Error();
      } catch (err: Error | any) {
        toast.error("Request failed", { description: err.message });
      }
    }
  };

  const {
    formState,
    handleSubmit,
    setValue,
    register,
  } = useForm<ClientSignOffFormObject>({
    context: { type: "MANUAL" },
    resolver: yupResolver(clientSignOffSchema) as Resolver<ClientSignOffFormObject>,
    mode: "onChange",
    defaultValues: {
      signOffDocument: [
        {
          file: "",
        },
      ],
      deliverables: [],
    },
  });

  const handleDeliverableSelection = (deliverableId: number) => {
    setSelectedDeliverables((prevSelectedDeliverables) => {
      if (!prevSelectedDeliverables) {
        return [deliverableId];
      } else if (prevSelectedDeliverables.includes(deliverableId)) {
        return prevSelectedDeliverables.filter((id) => id !== deliverableId);
      } else {
        return [...prevSelectedDeliverables, deliverableId];
      }
    });
  };

  const filteredDeliverables = useMemo(() => {
    if (!campaign || !campaign.deliverables) {
      return [];
    }

    return campaign.deliverables.filter((deliverable) => deliverable.status === "NOT_COMPLETED");
  }, [campaign]);

  const deliverables = useMemo(() => {
    if (!campaign || !campaign.deliverables) {
      return null;
    }

    if (filteredDeliverables.length === 0) {
      return <p className="text-sm text-center">No deliverables</p>;
    }

    return filteredDeliverables.map((deliverable) => (
      <MonetSwitch
        key={deliverable.deliverableId}
        label={deliverable.name}
        showInRow={true}
        showLabels={false}
        size="small"
        onClick={() => handleDeliverableSelection(deliverable.deliverableId)}
        checked={selectedDeliverables?.includes(deliverable.deliverableId)}
      />
    ));
  }, [filteredDeliverables, selectedDeliverables]);

  useEffect(() => {
    if (selectedDeliverables) {
      setValue("deliverables", selectedDeliverables, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
    }
  }, [selectedDeliverables]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col flex-1 justify-between mt-2 gap-6">
      <div className="flex flex-col gap-2">
        <div>
          <p className="text-sm font-semibold">Deliverables</p>
          <p className="text-sm">Select the deliverables that have been signed off</p>
        </div>
        <ul className="flex flex-col sm:gap-1 mb-5">
          <MonetBorderedCardContent className="max-h-[150px] overflow-auto mt-2">{deliverables}</MonetBorderedCardContent>
          {formState.errors.deliverables?.message && <p className="text-sm text-red-500 mt-2">{formState.errors.deliverables?.message as string}</p>}
        </ul>
        <div className="flex flex-col gap-2">
          <div className="flex flex-col">
            <p className="text-sm font-semibold">Client sign off file</p>
            <p className="text-sm">Upload proof of the client sign off. Supported files are png, jpeg, jpg, docx, pdf</p>
          </div>
          <MonetFileInput {...register(`signOffDocument.0.file`)} disabled={formState.isSubmitting} error={formState.errors.signOffDocument?.[0]?.file?.message as string} />
        </div>
      </div>
      <MonetDrawerButtons cancelBtnText="Back" onCancel={() => onBackCallback(undefined)} cancelDisabled={formState.isSubmitting} submitDisabled={formState.isSubmitting || !formState.isValid} loading={formState.isSubmitting} />
    </form>
  );
};

export default ManualSignOffView;

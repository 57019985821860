import MonetAlert, { MonetAlertProps } from "components/tailwind/MonetAlert";
import MonetAuthTitle from "components/tailwind/auth/MonetAuthTitle";
import MonetInput from "components/tailwind/form/MonetInput";
import TailwindSelectInput from "components/tailwind/headlessTailwind/TailwindSelectInput";
import { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { getTurnoversForInput, industries } from "utils/Partner";
import { getAllCountries } from "utils/country";

type CompanyStepProps = {
  isLoading: boolean;
  alert?: MonetAlertProps;
};

const CompanyStep: React.FC<CompanyStepProps> = ({ isLoading, alert }) => {
  const {
    register,
    formState: { errors },
    watch,
    control,
    setValue,
  } = useFormContext();

  const country = watch("country");

  const turnoverOptions = useMemo(() => {
    if (country) {
      setValue("revenue", undefined);
      return getTurnoversForInput(country);
    } else return undefined;
  }, [country]);

  return (
    <div className="flex flex-col gap-6">
      <MonetAuthTitle subtitle="Create your Monet account today and gain access to instant funding">Sign up</MonetAuthTitle>
      {alert && (
        <div className="flex flex-col gap-4">
          <MonetAlert variant="error" {...alert} />
        </div>
      )}
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <MonetInput
            {...register("companyName")}
            label="Legal company name"
            id="companyName"
            data-testid="signUp-companyNameFormField"
            error={errors.companyName?.message as string}
            disabled={isLoading}
          />
          <Controller
            control={control}
            name="country"
            render={({ field: { ...inputProps } }) => (
              <TailwindSelectInput
                inputProps={inputProps}
                label="Country of registration"
                options={getAllCountries()}
                placeholder="Please select..."
                error={errors.invoiceStatus?.message as string}
              />
            )}
          />
        </div>
        <Controller
          control={control}
          name="industry"
          render={({ field: { ...inputProps } }) => (
            <TailwindSelectInput
              inputProps={inputProps}
              label="Industry"
              options={industries.map((x) => ({ label: x, value: x }))}
              placeholder="Please select..."
              error={errors.industry?.message as string}
              disabled={isLoading}
            />
          )}
        />

        <Controller
          control={control}
          name="revenue"
          render={({ field: { ...inputProps } }) => (
            <TailwindSelectInput
              inputProps={inputProps}
              label="Revenue"
              options={turnoverOptions || []}
              placeholder="Please select..."
              error={errors.revenue?.message as string}
              disabled={!country || isLoading}
            />
          )}
        />
        <MonetInput
          {...register("website")}
          label="Company website"
          id="website"
          data-testid="signUp-websiteFormField"
          error={errors.website?.message as string}
          disabled={isLoading}
        />
        <MonetInput
          {...register("position")}
          label="Your role in the company"
          id="position"
          data-testid="signUp-positionFormField"
          error={errors.position?.message as string}
          disabled={isLoading}
        />
      </div>
    </div>
  );
};

export default CompanyStep;

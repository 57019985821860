import { useMemo } from "react";
import MonetCard from "./MonetCard";
import MonetLoadingSpinner from "./MonetLoadingSpinner";
import MonetToolTip from "./MonetTooltip";

type MonetMetricProps = {
  label?: string | React.ReactNode;
  value?: string | number | React.ReactNode;
  loading?: boolean;
  trend?: {
    value: string;
    trending: "up" | "down";
  };
  tooltip?: string;
};

const MonetMetric: React.FC<MonetMetricProps> = ({ label, value, trend, tooltip, loading }) => {
  const metricValue = useMemo(() => {
    if (loading) {
      return <MonetLoadingSpinner size="large" />;
    } else if (!value) {
      return "-";
    } else {
      return value;
    }
  }, [loading, value]);

  return (
    <MonetCard className="min-h-[86px] gap-2">
      <h2 className="text-sm text-neutral-800 dark:text-neutral-400">
        {label}
        {tooltip && <MonetToolTip text={tooltip} />}
      </h2>
      <div className="flex flex-1 items-start text-lg md:text-xl font-semibold text-stone-800 dark:text-neutral-200">
        {metricValue}
        {trend && <span>{trend.value}</span>}
      </div>
    </MonetCard>
  );
};

export default MonetMetric;

import { Money, MoneyStorage } from "@monet-money/money-type";
import { MonetBadgeVariants } from "components/tailwind/MonetBadge";
import { Campaign, DeliverableStatus } from "shared/types/Campaign";
import { CreateInvoiceFormObject } from "shared/utils/validators";
import { EP3User } from "utils/User";
import { uploadFileToS3 } from "utils/file";
import { CampaignDocuments } from "./validationSchema/UploadCampaignDocumentSchema";

export type CampaignStatusSettings = {
  color: MonetBadgeVariants["variant"];
};

export type CurrentViewType = "Details" | "Edit";

export const getCampaignStatusColor = (status: Campaign["campaignStatus"]): CampaignStatusSettings => {
  switch (status) {
    case "ACTIVE": {
      return {
        color: "green",
      };
    }
    case "COMPLETED": {
      return {
        color: "blue",
      };
    }
    default: {
      return {
        color: "gray",
      };
    }
  }
};

export const getCampaignDeliverableStatusSettings = (status: DeliverableStatus): { color: MonetBadgeVariants["variant"] } => {
  switch (status) {
    case "NOT_COMPLETED":
      return {
        color: "gray",
      };
    case "SIGNED_OFF":
      return {
        color: "green",
      };
    case "PENDING_SIGN_OFF":
      return {
        color: "blue",
      };
    default:
      return {
        color: "gray",
      };
  }
};

export const getCampaignRemainingValue = (campaign: Campaign, formatAsNumericString: boolean) => {
  if (campaign.budgetLimit && campaign.allocatedFunds) {
    const budgetLimit = campaign.budgetLimit as MoneyStorage;
    const allocatedFunds = campaign.allocatedFunds as MoneyStorage;

    if (budgetLimit?.amount && allocatedFunds.amount && budgetLimit.currency == allocatedFunds.currency) {
      const remaining = Money.fromStorageType(budgetLimit).subtract(Money.fromStorageType(allocatedFunds));
      return remaining.format(formatAsNumericString, formatAsNumericString);
    }
    if (allocatedFunds.amount == 0 && campaign.campaignStatus == "ACTIVE") {
      return Money.fromStorageType(budgetLimit).format(formatAsNumericString, formatAsNumericString);
    }
    return formatAsNumericString ? "N/A" : 0;
  } else return "N/A";
};

export const uploadCampaignFiles = async <T extends CampaignDocuments[] | CreateInvoiceFormObject["invoiceDocument"]>(files: T, user: EP3User, campaignId: string): Promise<T> => {
  const uploadedFiles = files;
  for (let index = 0; index < files.length; index++) {
    const item = files[index];
    const name = item.file[0].name.replace(/\s+/g, "");

    const key = `partners/${user.partner}/campaigns/${campaignId}/${name}`;
    await uploadFileToS3(key, item.file[0]);

    const fullPath = `https://${process.env.REACT_APP_EEP_CAMPAIGN_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/private/${key}`;
    uploadedFiles[index].file = fullPath;
  }
  return uploadedFiles;
};

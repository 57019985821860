import { Invoice, InvoiceFundingStatus } from "types/Invoice";
import { Payout } from "types/Payout";
import { Campaign } from "../types/Campaign";

export const PAYOUT_TYPES_LIST: Payout["payoutType"][] = ["VENDOR", "WITHDRAWAL"];
export const CAMPAIGN_STATUSES: Campaign["campaignStatus"][] = ["ACTIVE", "COMPLETED"];
export const PAYOUT_STATUSES: Payout["payoutStatus"][] = [
  "CREATED",
  "BENEFICIARY_ACCEPTED",
  "MONET_ACCEPTED",
  "BENEFICIARY_PAID",
  "SETTLED",
  "MONET_REJECTED",
  "PAYMENT_FAILED",
  "PAYMENT_PROCESSING",
  "EXPIRED",
];
export const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";
export const DEFAULT_NUMERIC_DATE = "YYYYMMDD";
export const SYSTEM_ALLOWED_CURRENCIES = ["GBP", "EUR", "USD", "AUD", "CAD"];
export const AWS_TRANSACTION_BATCH_SIZE = 100;
export const BORDERLESS_SUPPORTED_COUNTRIES_SETTING_NAME = "BORDERLESS_SUPPORTED_COUNTRIES";
export const BORDERLESS_PAYMENT_WEBHOOK_STATUSES = {
  complete: ["COMPLETE"],
  processing: ["PENDING", "SCHEDULED", "REQUESTED", "PROCESSING", "IN_TRANSIT"],
  failed: ["UNCLAIMED", "CANCELED", "DECLINED", "FAILED", "RETURNED"],
};
export const UNSETTLED_PAYOUT_STATUSES = ["CREATED", "BENEFICIARY_ACCEPTED", "MONET_ACCEPTED", "PAYMENT_PROCESSING"];
export const CEASED_PAYOUT_STATUSES = ["MONET_REJECTED", "EXPIRED"];
export const INVOICE_FUNDING_STATUSES: InvoiceFundingStatus[] = ["SUBMITTED", "ACTIVE", "REJECTED", "SETTLED"];
export const INVOICE_STATUSES: Invoice["invoiceStatus"][] = ["DUE", "OVERDUE", "SETTLED"];
export const AGGREGATE_DATE_FORMAT = "MMM YYYY";
export const DUE_DATE_FORMAT = "YYYYMMDD";
export const DATETIME_STRING_FORMAT = "DD/MM/YYYY HH:mm:ss";
export const MAX_IMAGE_SIZE = 400; // Pixels
export const ALLOWED_IMAGE_EXTENSIONS = ["jpg", "png", "jpeg"];
export const MAX_FILE_SIZE_IN_MB = 10 * 1024 * 1024; // 10MB

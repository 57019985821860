import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import MonetBasicStepper from "components/tailwind/MonetBasicStepper";
import { useSessionProvider } from "contexts/SessionProvider";
import { HSStepper } from "preline/preline";
import { useCallback, useEffect, useMemo, useState } from "react";
import CodatStep from "./steps/Codat/CodatStep";
import ProfileStep from "./steps/ProfileStep";
import SubscriptionsStep from "./steps/StripeSubscriptions/SubscriptionsStep";

const OnboardingModal: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [previousStep, setPreviousStep] = useState(1);

  const { partner, user, refetchCurrentPartner } = useSessionProvider();

  const subscriptionStepCompleted = useMemo(() => {
    return partner?.onboarding?.subscription === "COMPLETED";
  }, [partner]);

  const profileStepCompleted = useMemo(() => {
    return partner?.onboarding?.orgainsationProfile === "COMPLETED";
  }, [partner]);

  const handleNextStep = useCallback(() => {
    const stepper = HSStepper.getInstance(`#onboardingStepper`) as HSStepper;
    if (stepper && currentStep !== stepperSteps.length && currentStep !== previousStep) {
      stepper.goToNext();
      setCurrentStep((prev) => prev + 1);
      setPreviousStep(currentStep);
    }
  }, [currentStep, previousStep]);

  useEffect(() => {
    if (subscriptionStepCompleted) {
      setCurrentStep(2);
    }
    if (profileStepCompleted) {
      setCurrentStep(3);
    }
  }, [subscriptionStepCompleted, profileStepCompleted]);

  const stepperSteps = [
    { index: 1, label: "Subscriptions", content: <SubscriptionsStep handleNextStep={handleNextStep} />, completed: subscriptionStepCompleted },
    { index: 2, label: "Profile", content: <ProfileStep handleNextStep={handleNextStep} />, completed: profileStepCompleted },
    { index: 3, label: "Integrations", content: <CodatStep refetchCurrentPartner={refetchCurrentPartner} /> },
  ];

  const getCurrentContent = () => {
    const step = stepperSteps.find((step) => step.index === currentStep);
    return step?.content;
  };

  if (user.isAdministrator || partner?.onboarding?.status === "COMPLETED") return null;

  return (
    <Dialog static open={true} onClose={() => null} className="relative z-50">
      <DialogBackdrop className="fixed inset-0 bg-black/30" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel className="flex flex-col w-full h-full max-w-[1000px] max-h-[800px] bg-white border shadow-sm overflow-auto p-6 rounded-lg pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
          <MonetBasicStepper steps={stepperSteps} currentStep={currentStep} />
          {getCurrentContent()}
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default OnboardingModal;

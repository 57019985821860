import { MAX_FILE_SIZE_IN_MB } from "shared/constants/Constants";
import { SchemaOf, object, string, array, boolean, number, mixed } from "yup";

export type ClientSignOffFormObject = {
  deliverables: number[],
  signOffDocument?: {
    file: any,
  }[],
  confirmation?: boolean,
};

const validInvoiceExtensions: string[] = ["png", "jpg", "jpeg", "pdf", "docx", "doc"];

function isValidFileType(value: any) {
  if (value && typeof value === "object") {
    return validInvoiceExtensions.includes(value.name.split(".").pop()!);
  } else return false;
}

export const clientSignOffSchema = <SchemaOf<ClientSignOffFormObject>>object()
  .shape({
    deliverables: array().of(number()).min(1, "Please select at least one deliverable").required(),
    signOffDocument: array().when("$type", {
        is: "MANUAL",
        then: array().of(
          object()
            .shape({
              file: mixed()
                .test("is-required", "Please select a file to upload", (value) => {
                  if (value && value.length === 0) {
                    return false;
                  }
                  return true;
                })
                .test("is-valid-type", `Invalid file. Only the following types are allowed: ${validInvoiceExtensions.join(", ")}`, (value) => isValidFileType(value[0]))
                .test("is-valid-size", "File to large. Maximum file size is 5MB", (value) => value.length > 0 && value[0].size <= MAX_FILE_SIZE_IN_MB)
                .notRequired(),
            })
            .notRequired(),
        ),
        otherwise: array().notRequired(),
      }),
    confirmation: boolean()
        .when("$type", {
        is: "MONET_SIGNOFF",
        then: array().of(
            object().shape({
                file: string().required("Please confirm"),
            })
        ),
        otherwise: boolean().notRequired(),
      }),
  })
